<template>
  <div class="wrapper signup-page">
    <!-- Header -->
    <Header />
    <!-- HeaderMenu -->
    <HeaderMenu />

    <div class="contents">
      <div class="layout">
        <div class="left"></div>
        <div class="right">
          <input class="c-checkbox" type="checkbox" id="start" />
          <input class="c-checkbox" type="checkbox" id="progress1" />
          <input class="c-checkbox" type="checkbox" id="progress2" />
          <input class="c-checkbox" type="checkbox" id="progress3" />
          <input class="c-checkbox" type="checkbox" id="finish" />

          <input class="c-checkbox return-check" type="checkbox" id="return" />
          <input class="c-checkbox return-check" type="checkbox" id="return1" />
          <input class="c-checkbox return-check" type="checkbox" id="return2" />
          <input class="c-checkbox return-check" type="checkbox" id="return3" />

          <div class="shutter" style="display: none"></div>
          <div class="c-form__progress"></div>
          <h1 class="toptext">
            {{ $t("SIGN UP") }}
          </h1>
          <div class="c-formContainer">
            <div class="c-welcome">
              {{ $t("WELCOME TO") }}<br />
              {{ $t("FDP SYSTEM") }}
              <div class="img">
                <div class="link-btn link-over" @click="onLink">
                  <img
                    src="../assets/img/signup-btn-img.svg"
                    alt=""
                    class="blink-animation"
                  />
                </div>
              </div>
            </div>
            <div class="btn">
              <form class="c-form" action="">
                <!-- 追加 -->
                <div class="c-form__group">
                  <!-- <p class="error-txt">紹介コードが間違っています。</p> -->
                  <label class="c-form__label" for="username">
                    <input
                      type="text"
                      id="username"
                      class="c-form__input"
                      v-model="username"
                      placeholder=" "
                      required
                    />
                    <label class="c-form__next" for="progress1" role="button">
                      <span class="c-form__nextIcon" @click="nextIconOne">
                        <img src="../assets/img/signin-arrow-img.svg" alt="" />
                      </span>
                    </label>

                    <span class="c-form__groupLabel">{{
                      $t("User name (in romaji)")
                    }}</span>
                    <b class="c-form__border"></b>
                  </label>
                  <button class="return-txt" type="button" @click="returnOne">
                    ◀︎ Back
                  </button>
                </div>
                <!-- ./追加 -->
                <!-- 2つ目 -->
                <div class="c-form__group">
                  <label class="c-form__label" for="email">
                    <input
                      type="email"
                      id="email"
                      v-model="email"
                      class="c-form__input"
                      placeholder=" "
                      required
                    />
                    <label class="c-form__next" for="progress2" role="button">
                      <span class="c-form__nextIcon" @click="nextIconEmail">
                        <img src="../assets/img/signin-arrow-img.svg" alt="" />
                      </span>
                    </label>

                    <span class="c-form__groupLabel">{{
                      $t("YOUR EMAIL")
                    }}</span>
                    <b class="c-form__border"></b>
                  </label>
                  <button class="return-txt" type="button" @click="returnTwo">
                    ◀︎ Back
                  </button>
                </div>
                <!-- ./2つ目 -->

                <!-- 3つ目 -->
                <div class="c-form__group">
                  <label class="c-form__label" for="fpass">
                    <input
                      type="password"
                      id="fpass"
                      class="c-form__input"
                      placeholder=" "
                      v-model="password"
                    />

                    <label class="c-form__next" for="progress3" role="button">
                      <span class="c-form__nextIcon" @click="nextIconTwo"
                        ><img src="../assets/img/signin-arrow-img.svg" alt=""
                      /></span>
                    </label>

                    <span class="c-form__groupLabel">{{ $t("PASSWORD") }}</span>
                    <b class="c-form__border"></b>
                  </label>
                  <button class="return-txt" type="button" @click="returnThree">
                    ◀︎ Back
                  </button>
                </div>

                <!-- ./3つ目 -->

                <!-- 4つ目 -->
                <div class="c-form__group">
                  <label class="c-form__label" for="fpass3">
                    <input
                      type="password"
                      id="fpass3"
                      class="c-form__input"
                      placeholder=" "
                      v-model="confirmPassword"
                    />

                    <label class="c-form__next" for="progress4" role="button">
                      <span class="c-form__nextIcon" @click="nextIconThree"
                        ><img src="../assets/img/signin-arrow-img.svg" alt=""
                      /></span>
                    </label>

                    <span class="c-form__groupLabel">{{
                      $t("CONFIRM PASSWORD")
                    }}</span>
                    <b class="c-form__border"></b>
                  </label>
                  <button class="return-txt" type="button" @click="returnFour">
                    ◀︎ Back
                  </button>
                </div>
                <!-- ./4つ目 -->
                <!-- 5つ目 -->
                <div class="c-form__group pdf-area">
                  <label class="c-form__label" for="fpass2">
                    <div class="pdf">
                      <img src="../assets/pdf/terms-pdf-1.jpg" alt="" />
                      <img src="../assets/pdf/terms-pdf-2.jpg" alt="" />
                      <img src="../assets/pdf/terms-pdf-3.jpg" alt="" />
                      <img src="../assets/pdf/terms-pdf-4.jpg" alt="" />
                      <img src="../assets/pdf/terms-pdf-5.jpg" alt="" />
                      <img src="../assets/pdf/terms-pdf-6.jpg" alt="" />
                      <img src="../assets/pdf/terms-pdf-7.jpg" alt="" />
                      <img src="../assets/pdf/terms-pdf-8.jpg" alt="" />
                      <img src="../assets/pdf/terms-pdf-9.jpg" alt="" />
                      <img src="../assets/pdf/terms-pdf-10.jpg" alt="" />
                      <img src="../assets/pdf/terms-pdf-11.jpg" alt="" />
                    </div>
                    <!-- 利用規約に同意する -->
                    <div class="input-area2">
                      <label class="cover-label" for="check">
                        <input
                          type="checkbox"
                          name=""
                          id="check"
                          placeholder=" "
                          v-model="userCheck"
                        />
                        {{ $t("I agree with the Terms") }}
                      </label>
                      <span
                        class="circle"
                        v-bind:class="{
                          done: hasChecked,
                        }"
                      >
                        <span class="bar"></span>
                      </span>
                    </div>
                    <!-- 名前 -->
                    <!-- <div class="input-area">
                      <label class="label" for="name">
                        <input
                          type="text"
                          name=""
                          id="name"
                          placeholder=" "
                          v-model="realname"
                        />
                        <span class="group-label">{{ $t("Name") }}</span>
                      </label>
                      <span
                        class="circle"
                        v-bind:class="{
                          done: hasUserName,
                        }"
                      >
                        <span class="bar"></span>
                      </span>
                    </div> -->
                    <!-- 電話番号 -->
                    <!-- <div class="input-area">
                      <label class="label" for="tel">
                        <input
                          type="text"
                          name=""
                          id="tel"
                          placeholder=" "
                          v-model="userTel"
                        />
                        <span class="group-label">{{
                          $t("PHONE NUMBER")
                        }}</span>
                      </label>
                      <span
                        class="circle"
                        v-bind:class="{
                          done: hasUserTel,
                        }"
                      >
                        <span class="bar"></span>
                      </span>
                    </div> -->
                    <!-- 介绍人 -->
                    <div class="input-area">
                      <label class="label" for="tel">
                        <input
                          type="text"
                          name=""
                          id="intr"
                          placeholder=" "
                          v-model="introducerPeople"
                          :disabled="showIntroducer"
                        />
                        <span class="group-label">{{ $t("REFERRAL") }}</span>
                      </label>
                      <span
                        class="circle"
                        v-bind:class="{
                          done: hasUserPeople,
                        }"
                      >
                        <span class="bar"></span>
                      </span>
                    </div>
                    <!-- プレーサー -->
                    <div class="input-area">
                      <label class="label" for="tel">
                        <input
                          type="text"
                          name=""
                          id="loca"
                          placeholder=" "
                          v-model="SeatUser"
                          :disabled="showSeatUser"
                        />
                        <span class="group-label"
                          >{{ $t("Seat user") }} ({{ $t("Any") }})</span
                        >
                      </label>
                      <span
                        class="circle"
                        v-bind:class="{
                          done: hasUserLocations,
                        }"
                      >
                        <span class="bar"></span>
                      </span>
                    </div>
                    <van-radio-group
                      v-model="radio"
                      direction="horizontal"
                      :disabled="showSeatNo"
                    >
                      <van-radio class="singleChoice" name="1">{{
                        $t("Left")
                      }}</van-radio>
                      <van-radio class="singleChoice" name="2">{{
                        $t("Right")
                      }}</van-radio>
                    </van-radio-group>
                    <button
                      class="done-btn c-form__nextIcon"
                      for="finish"
                      role="button"
                      type="button"
                      :disabled="addBtn"
                      @click="register"
                    >
                      {{ $t("Completed signing") }}
                    </button>
                  </label>
                  <button class="return-txt" type="button" @click="returnFive">
                    ◀︎ Back
                  </button>
                </div>
                <!-- ./5つ目 -->

                <!-- 1つ目 -->
                <label class="c-form__toggle" for="start" @click="onFadeOut">{{
                  $t("REGISTER")
                }}</label>
                <!-- ./1つ目 -->
              </form>
            </div>
          </div>
          <div class="fix-bar">
            <a class="link-over" @click="onSignIn">{{ $t("SIGN IN") }}</a>
          </div>
        </div>
      </div>
    </div>
    <!-- /.contents -->
    <van-overlay :show="loading">
      <div class="loading-position" @click.stop>
        <van-loading type="spinner" color="#1989fa" />
      </div>
    </van-overlay>
    <!--<Footer class="pc-only" title="Sign up" />-->
  </div>
</template>
<script>
import $ from "jquery";
import Account from "@/js/api/account";
import Header from "@/components/Header.vue";
import { Notify } from "vant";
import HeaderMenu from "@/components/HeaderMenu.vue";
// import Footer from "@/components/Footer.vue";
export default {
  name: "Signup",
  components: {
    Header,
    HeaderMenu,
    // Footer,
  },
  data: () => {
    return {
      username: "",
      email: "",
      password: "",
      confirmPassword: "",
      userCheck: "",
      userTel: "",
      realname: "",
      introducerPeople: "",
      SeatUser: "",
      radio: "",
      showIntroducer: false,
      showSeatUser: false,
      showSeatNo: false,
      loading: false,
      isOut: true,
    };
  },
  computed: {
    hasChecked() {
      return this.userCheck;
    },
    hasUserName() {
      return this.realname;
    },
    hasUserTel() {
      return this.userTel;
    },
    hasUserPeople() {
      return this.introducerPeople;
    },
    hasUserLocations() {
      return this.SeatUser;
    },
    addBtn() {
      if (
        // this.realname &&
        // this.userTel &&
        this.introducerPeople &&
        this.radio &&
        this.userCheck
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  mounted: function () {
    this.init();
  },
  created() {
    this.onIOS();
  },
  methods: {
    init() {
      if (this.$route.query.Introducer) {
        this.SeatUser = this.$route.query.SeatUser;
        this.radio = this.$route.query.SeatNo;
        this.introducerPeople = this.$route.query.Introducer;
        if (this.$route.query.SeatUser) {
          this.showSeatUser = true;
        }
        if (this.$route.query.SeatNo) {
          this.showSeatNo = true;
          if (this.$route.query.SeatUser) {
            this.isOut = false;
          }
        }
        if (this.$route.query.Introducer) {
          this.showIntroducer = true;
        }
      }
    },
    onIOS() {
      let ua = navigator.userAgent.toLowerCase();
      let isiOS = ua.indexOf("iphone") > -1 || ua.indexOf("ipad") > -1;
      if (isiOS) {
        let viewport = document.querySelector('meta[name="viewport"]');
        if (viewport) {
          let viewportContent = viewport.getAttribute("content");
          viewport.setAttribute(
            "content",
            viewportContent + ", user-scalable=no"
          );
        }
      }
    },
    onFadeOut() {
      $(".fix-bar").fadeOut("slow");
      $(".c-form__toggle").addClass("remove");
      $(".c-form__group:nth-child(1)").addClass("active");
    },
    onLink() {
      let _this = this;
      $(".shutter")
        .css("display", "block")
        .delay(2090)
        .queue(function () {
          // let params = {
          //   Username: _this.username,
          //   Password: _this.password,
          // };
          // Account.login(params).then((response) => {
          //   if (response) {
          //     // Common.loggedIn(
          //     //   response.data.access_token,
          //     //   response.data.refresh_token
          //     // );
          //     _this.$router.push({ name: "Dashboard" });
          //   }
          // });
          _this.$router.push({ name: "Dashboard" });
          $(this).dequeue();
        });
    },
    returnOne() {
      $(".c-form__toggle").removeClass("remove");
      $(".c-form__group:nth-child(1)").removeClass("active");
      $(".fix-bar").fadeIn("slow");
    },
    returnTwo() {
      $(".c-form__group:nth-child(2)").removeClass("active");
      $(".c-form__group:nth-child(1)").addClass("active");
      $(".c-form__progress").removeClass("progress1");
    },
    returnThree() {
      $(".c-form__group:nth-child(3)").removeClass("active");
      $(".c-form__group:nth-child(2)").addClass("active");
      $(".c-form__progress").removeClass("progress2");
      $(".c-form__progress").addClass("progress1");
    },
    returnFour() {
      $(".c-form__group:nth-child(4)").removeClass("active");
      $(".c-form__group:nth-child(3)").addClass("active");
      $(".c-form__progress").removeClass("progress3");
      $(".c-form__progress").addClass("progress2");
    },
    returnFive() {
      $(".c-form__group:nth-child(5)").removeClass("active");
      $(".c-form__group:nth-child(4)").addClass("active");
      $(".c-form__progress").removeClass("progress3");
      $(".c-form__progress").addClass("progress2");
    },
    onSignIn() {
      this.$router.push({ name: "Signin" });
    },
    register() {
      let params = {
        Email: this.email,
        Username: this.username,
        Password: this.password,
        ConfirmPassword: this.confirmPassword,
        Introducer: this.introducerPeople,
        SeatUser: this.SeatUser ? this.SeatUser : this.introducerPeople,
        SeatNo: this.radio,
        RealName: this.realname,
        PhoneNumber: this.userTel,
        isOut: this.isOut,
      };
      this.loading = true;
      Account.register(params)
        .then((response) => {
          if (response) {
            $(".c-form__group:nth-child(5)").removeClass("active");
            $(".c-formContainer .c-form").addClass("active");
            $(".c-formContainer .c-welcome").addClass("active");
            $(".c-form__progress").addClass("progress5");
          }
        })
        .finally(() => (this.loading = false));
    },
    nextIconOne() {
      if (this.username) {
        this.loading = true;
        Account.getExistedUser(this.username).then((res) => {
          this.loading = false;
          if (res) {
            if (res.data) {
              Notify({ type: "danger", message: this.$t("Already Exists.") });
            } else {
              $(".c-form__group:nth-child(1)").removeClass("active");
              $(".c-form__group:nth-child(2)").addClass("active");
              $(".c-form__progress").addClass("progress1");
            }
          }
        });
      } else {
        Notify({
          type: "danger",
          message: this.$t("{item} is required.", {
            item: this.$t("User name (in romaji)"),
          }),
        });
      }
    },
    nextIconEmail() {
      if (this.email) {
        // if (this.password.length > 5) {
        $(".c-form__group:nth-child(2)").removeClass("active");
        $(".c-form__group:nth-child(3)").addClass("active");
        $(".c-form__progress").addClass("progress2");
        // } else {
        //   Notify({
        //     type: "danger",
        //     message: this.$t(
        //       "The Password must be at least 6 and at max 100 characters long."
        //     ),
        //   });
        // }
      } else {
        Notify({
          type: "danger",
          message: this.$t("{item} is required.", {
            item: this.$t("YOUR EMAIL"),
          }),
        });
      }
    },
    nextIconTwo() {
      if (this.password) {
        if (this.password.length > 5) {
          $(".c-form__group:nth-child(3)").removeClass("active");
          $(".c-form__group:nth-child(4)").addClass("active");
          $(".c-form__progress").addClass("progress2");
        } else {
          Notify({
            type: "danger",
            message: this.$t(
              "The Password must be at least 6 and at max 100 characters long."
            ),
          });
        }
      } else {
        Notify({
          type: "danger",
          message: this.$t("{item} is required.", {
            item: this.$t("PASSWORD"),
          }),
        });
      }
    },
    nextIconThree() {
      if (this.confirmPassword == this.password) {
        $(".c-form__group:nth-child(4)").removeClass("active");
        $(".c-form__group:nth-child(5)").addClass("active");
        $(".c-form__progress").addClass("progress3");
      } else {
        Notify({
          type: "danger",
          message: this.$t(
            "The password and confirmation password do not match."
          ),
        });
      }
    },
    nextIconFour() {
      if (this.introducer) {
        let params = {
          Username: this.username,
          Password: this.password,
          ConfirmPassword: this.confirmPassword,
          Introducer: this.introducer,
        };
        Account.register(params).then((response) => {
          if (response) {
            $(".c-form__group:nth-child(4)").removeClass("active");
            $(".c-formContainer .c-form").addClass("active");
            $(".c-formContainer .c-welcome").addClass("active");
            $(".c-form__progress").addClass("progress4");
            // Common.loggedIn(response.data.access_token, response.data.refresh_token);
            // // Common.success(i18n.t("Register succeed."));
            // Router.push({ path: "/" });
          }
        });
      } else {
        Notify({
          type: "danger",
          message: this.$t("{item} is required.", {
            item: this.$t("REFERRAL"),
          }),
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.imgpdf {
  height: 200px;
  width: 100%;
}
.toptext {
  position: absolute;
  top: 10px;
}
.van-radio-group {
  position: absolute;
  left: calc(50% - 54px);
  bottom: 60px;
}
.done-btn {
  position: relative;
  top: 15px;
}

.header {
  :deep(.grade) {
    display: none !important;
  }
  :deep(.detail) {
    display: none !important;
  }
}
:deep(.van-radio__label) {
  color: aliceblue !important;
}
:disabled {
  opacity: 0.4;
}
.loading-position {
  position: absolute;
  left: calc(50% - 15px);
  top: calc(50% - 15px);
}

.input-area2 {
  background-color: transparent;
  box-shadow: none;
  padding: 0;
  height: auto;
  margin: 30px 0 0px;
  height: 54px;
  position: relative;
  width: 100%;
  input {
    display: none;
  }
  .cover-label {
    display: block;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 12px 36px;
    border-radius: 30px;
    color: #fff;
  }
  .circle.done .bar {
    display: block;
    position: absolute;
    top: 28%;
    left: 50%;
    width: 10px;
    height: 5px;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transform: rotate(-45deg) translateY(-50%) translateX(-50%);
  }
  .circle {
    background-color: rgba(255, 255, 255, 0.2);
    width: 24px;
    height: 24px;
    background-color: #d1d1d1;
    border-radius: 50%;
    position: absolute;
    top: 42%;
    right: -1%;
    transform: translateY(-50%) translateX(-50%);
    transition: 0.2s;
    &.done {
      background-color: #3e6cf2;
    }
  }
}
</style>
