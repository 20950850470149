<template>
  <div class="information">
    <p class="label" v-show="data.userTitle !== ''">
      {{ data.userTitle }}
    </p>
    <ul class="detail-list">
      <li>ID：</li>
      <li>{{ data.userName }}</li>
    </ul>
    <ul class="detail-list">
      <li>{{ $t("PACKAGE") }}：</li>
      <li>{{ data.stage }}</li>
    </ul>
    <ul class="detail-list">
      <li>LEFT：</li>
      <li>{{ data.performance1 }}</li>
    </ul>
    <ul class="detail-list">
      <li>RIGHT：</li>
      <li>{{ data.performance2 }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "OrganizationSub",
  props: ["data"],
  setup() {},
};
</script>

<style scoped lang="less">
.label {
  color: #1105ff;
  font-size: 10px;
  font-weight: 600;
  display: inline-block;
  border: 2px solid #1105ff;
  border-radius: 6px;
  padding: 4px 18px;
  margin: 0 10px;
  text-align: center;
}
.detail-list {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 600;
  margin: 10px;
  word-break: break-all;
  li {
    &:first-child {
      padding-right: 4px;
      width: 60%;
    }
    &:last-child {
      width: 53%;
      font-weight: 400;
      font-size: 10px;
      text-align: right;
      span {
        font-size: 12px;
        display: inline-block;
        margin-right: 4px;
      }
    }
  }
}
</style>
