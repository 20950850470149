<template>
  <div class="wrapper bridge-page">
    <!-- Header -->
    <Header />
    <!-- HeaderMenu -->
    <HeaderMenu />

    <div class="contents">
      <div class="layout">
        <div class="left">
          <div class="pc-only">
            <Sidebar />
          </div>
        </div>
        <div class="right">
          <div class="bridge-box">
            <p>
              {{
                userInfo.isRePlate
                  ? $t("Those who have been staking for 100 days")
                  : $t("Those who have been staking for 365 days")
              }}<br />
              {{ $t("please select [RE-STAKING]") }}<br />
              {{ $t("or [WITHDRAWAL]") }}
            </p>
            <div class="btn">
              <div>
                <router-link to="./staking" class="link-over">{{
                  $t("RE-STAKING")
                }}</router-link>
              </div>
              <br />
              <div>
                <router-link to="./withdrawfp" class="link-over">{{
                  $t("WITHDRAWAL")
                }}</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.contents -->

    <Footer title="Bridge" />
  </div>
</template>
<style scoped></style>
<script>
import Header from "@/components/Header.vue";
import HeaderMenu from "@/components/HeaderMenu.vue";
import Sidebar from "@/components/Sidebar.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "Bridge",
  components: {
    Header,
    HeaderMenu,
    Sidebar,
    Footer,
  },
  data: () => {
    return {
      title: "Bridge",
      userInfo: {},
    };
  },
  created() {
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  },
  methods: {},
  computed: {},
  mounted: function () {},
};
</script>
