import { createRouter, createWebHashHistory } from "vue-router";
import Signup from "@/views/Signup.vue";
import Signin from "@/views/Signin.vue";
import Dashboard from "../views/Dashboard.vue";
import Deposit from "../views/Deposit.vue";
import History from "../views/History.vue";
import Withdraw from "../views/Withdraw.vue";
import Account from "../views/Account.vue";
import Referral from "../views/Referral.vue";
import OrganizationChart from "../views/OrganizationChart.vue";
import Transfer from "../views/Transfer.vue";
import Plan from "../views/Plan.vue";
import Authentication from "../views/Authentication.vue";
import Forgot from "../views/Forgot.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import ForgotPassword2 from "../views/ForgotPassword2.vue";
import ResetPassword from "../views/ResetPassword.vue";
import DonePassword from "../views/DonePassword.vue";
import ForgotAccount from "../views/ForgotAccount.vue";
import ForgotAccount2 from "../views/ForgotAccount2.vue";
import DoneAccount from "../views/DoneAccount.vue";
import Maintenance from "../views/Maintenance.vue";
import NewsList from "../views/NewsList.vue";
import News from "../views/News.vue";
import Terms from "../views/Terms.vue";
import SalesManagement from "../views/SalesManagement.vue";
import Contact from "../views/Contact.vue";
import Bridge from "../views/Bridge.vue";
import Staking from "../views/Staking.vue";
import WithdrawFp from "../views/WithdrawFp.vue";
const routes = [
  {
    path: "/Signup",
    name: "Signup",
    component: Signup,
  },
  {
    path: "/Signin",
    name: "Signin",
    component: Signin,
  },
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/Deposit",
    name: "Deposit",
    component: Deposit,
  },
  {
    path: "/History",
    name: "History",
    component: History,
  },
  {
    path: "/Withdraw",
    name: "Withdraw",
    component: Withdraw,
  },
  {
    path: "/Account",
    name: "Account",
    component: Account,
  },
  {
    path: "/Referral",
    name: "Referral",
    component: Referral,
  },
  {
    path: "/Transfer",
    name: "Transfer",
    component: Transfer,
  },
  {
    path: "/Plan",
    name: "Plan",
    component: Plan,
  },
  {
    path: "/OrganizationChart",
    name: "OrganizationChart",
    component: OrganizationChart,
  },
  {
    path: "/Authentication",
    name: "Authentication",
    component: Authentication,
  },
  {
    path: "/Forgot",
    name: "Forgot",
    component: Forgot,
  },
  {
    path: "/ForgotPassword",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/ForgotPassword2",
    name: "ForgotPassword2",
    component: ForgotPassword2,
  },
  {
    path: "/ResetPassword",
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/DonePassword",
    name: "DonePassword",
    component: DonePassword,
  },
  {
    path: "/ForgotAccount",
    name: "ForgotAccount",
    component: ForgotAccount,
  },
  {
    path: "/ForgotAccount2",
    name: "ForgotAccount2",
    component: ForgotAccount2,
  },
  {
    path: "/DoneAccount",
    name: "DoneAccount",
    component: DoneAccount,
  },
  {
    path: "/Maintenance",
    name: "Maintenance",
    component: Maintenance,
  },
  {
    path: "/NewsList",
    name: "NewsList",
    component: NewsList,
  },
  {
    path: "/News/:id",
    name: "News",
    component: News,
  },
  {
    path: "/Terms",
    name: "Terms",
    component: Terms,
  },
  {
    path: "/SalesManagement",
    name: "SalesManagement",
    component: SalesManagement,
  },
  {
    path: "/Contact",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/Bridge",
    name: "Bridge",
    component: Bridge,
  },
  {
    path: "/Staking",
    name: "Staking",
    component: Staking,
  },
  {
    path: "/WithdrawFp",
    name: "WithdrawFp",
    component: WithdrawFp,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;
