<template>
  <header class="header">
    <div class="inner">
      <div class="logo">
        <a href="./" class="link-over">
          <img src="../assets/img/logo-img.png" alt="FDP Logo" />
        </a>
      </div>
      <div class="detail pc-only">
        <p>
          <span class="txt">{{ $t("BALANCE") }}</span>
          <span class="number">
            <b>{{ ap }}</b
            >USD
          </span>
        </p>
      </div>
      <div class="sp-only grade" v-show="userTitle !== ''">
        <p class="bronze">{{ userTitle }}</p>
      </div>
      <div class="pc-only grade" v-show="userTitle !== ''">
        <p class="bronze">{{ userTitle }}</p>
      </div>
      <div class="menu link-over" v-on:click="toggleMenu" v-show="isLogin">
        <span class="line line1"></span>
        <span class="line line2"></span>
        <span class="line line3"></span>
      </div>
    </div>
    <div class="detail sp-only">
      <p>
        <span class="txt">{{ $t("BALANCE") }}</span>
        <span class="number">
          <b>{{ ap }}</b
          >USD
        </span>
      </p>
    </div>
    <div
      class="hidden-menu"
      v-bind:class="{
        active: active,
      }"
    >
      <!-- Hidden menu -->
      <div class="hidden-background"></div>
      <div class="hidden-inner">
        <div class="ttl">
          {{ $t("Menu") }}
          <div class="close-box link-over" v-on:click="toggleMenu">
            <span class="line line1"></span>
            <span class="line line2"></span>
          </div>
        </div>
        <div class="y-scroll">
          <ul class="hidden-menu-list">
            <li>
              <router-link to="./" class="link-over">
                <div class="img">
                  <img src="../assets/img/header-menu-icon1-img.png" alt="" />
                </div>
                <p>{{ $t("My Wallet") }}</p>
              </router-link>
            </li>
            <li v-if="isDepositRouterShow()">
              <router-link to="./deposit" class="link-over">
                <div class="img">
                  <img src="../assets/img/header-menu-icon2-img.png" alt="" />
                </div>
                <p>{{ $t("CHARGE") }}</p>
              </router-link>
            </li>
            <li v-if="isPlanRouterShow()">
              <router-link to="./plan" class="link-over">
                <div class="img">
                  <img src="../assets/img/header-menu-icon3-img.png" alt="" />
                </div>
                <p>{{ $t("BUYPLAN") }}</p>
              </router-link>
            </li>
            <li>
              <router-link to="./history" class="link-over">
                <div class="img">
                  <img src="../assets/img/header-menu-icon4-img.png" alt="" />
                </div>
                <p>{{ $t("HISTORY") }}</p>
              </router-link>
            </li>
            <li>
              <router-link to="./organizationchart" class="link-over">
                <div class="img">
                  <img src="../assets/img/header-menu-icon5-img.png" alt="" />
                </div>
                <p>{{ $t("GROUP") }}</p>
              </router-link>
            </li>
            <li>
              <router-link to="./withdraw" class="link-over">
                <div class="img">
                  <img src="../assets/img/header-menu-icon6-img.png" alt="" />
                </div>
                <p>{{ $t("Menu-Asset") }}</p>
              </router-link>
            </li>
            <li>
              <router-link to="./transfer" class="link-over">
                <div class="img">
                  <img src="../assets/img/header-menu-icon7-img.png" alt="" />
                </div>
                <p>{{ $t("Menu-Transfer / exchange") }}</p>
              </router-link>
            </li>
            <li>
              <router-link to="./account" class="link-over">
                <div class="img">
                  <img src="../assets/img/header-menu-icon8-img.png" alt="" />
                </div>
                <p>{{ $t("ACCOUNT") }}</p>
              </router-link>
            </li>
            <li>
              <router-link to="./referral" class="link-over">
                <div class="img">
                  <img src="../assets/img/header-menu-icon9-img.png" alt="" />
                </div>
                <p>{{ $t("Menu-REFERRAL CODE") }}</p>
              </router-link>
            </li>
            <li>
              <router-link to="./newslist" class="link-over">
                <div class="img">
                  <img src="../assets/img/header-menu-icon10-img.png" alt="" />
                </div>
                <p>{{ $t("NEWS") }}</p>
              </router-link>
            </li>
            <li>
              <router-link to="./salesManagement" class="link-over">
                <div class="img">
                  <img src="../assets/img/header-menu-icon11-img.png" alt="" />
                </div>
                <p>{{ $t("Sales management") }}</p>
              </router-link>
            </li>
            <li>
              <router-link to="./contact" class="link-over">
                <div class="img">
                  <img src="../assets/img/header-menu-icon12-img.png" alt="" />
                </div>
                <p>{{ $t("Contact") }}</p>
              </router-link>
            </li>
          </ul>
        </div>
        <div class="logout-txt">
          <span class="link-over" v-on:click="loggedOut">{{
            $t("SIGN OUT")
          }}</span>
        </div>
        <!-- <div style="text-align: center">
          <van-button type="primary" @click="loggedOut">{{
            $t("Log out")
          }}</van-button>
        </div> -->
      </div>
    </div>
  </header>
</template>

<script>
import Plate from "@/js/api/plate";
import Common from "@/js/common";
import LoginUser from "@/js/loginUser.js";
import Definitions from "@/js/definitions";

export default {
  name: "Header",
  props: {},
  data() {
    return {
      active: false,
      ap: 0,
      userTitle: "",
    };
  },
  methods: {
    toggleMenu() {
      this.active = !this.active;
    },
    getPlateInfo() {
      if (
        !Common.isEmpty(
          localStorage.getItem(Definitions.StorageKeys.accessToken)
        )
      ) {
        this.userTitle = LoginUser.getUserTitle();
        Plate.getPlateInfo().then((res) => {
          if (res) {
            this.$store.commit("setPlateInfo", res.data);
            this.ap = res.data.ap;
          }
        });
      }
    },
    isDepositRouterShow() {
      return Common.isDepositRouterShow();
    },
    loggedOut() {
      Common.loggedOut();
    },
    isPlanRouterShow() {
      return Common.isPlanRouterShow();
    },
  },
  mounted: function () {
    this.getPlateInfo();
  },
  computed: {
    isLogin() {
      return !Common.isEmpty(
        localStorage.getItem(Definitions.StorageKeys.accessToken)
      );
    },
  },
};
</script>

<style scoped lang="less"></style>
