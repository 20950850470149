<template>
  <div class="wrapper organization-page">
    <!-- Header -->
    <Header />
    <!-- HeaderMenu -->
    <HeaderMenu />

    <div class="contents">
      <div class="layout">
        <div class="left">
          <div class="pc-only">
            <Sidebar />
          </div>
        </div>
        <div class="right">
          <h1>{{ $t("Organization map") }}</h1>
          <div class="chart">
            <form>
              <div class="btn-area">
                <div class="btn link-over" @click="onSeatBottom('left')">
                  {{ $t("Last on the left") }}
                </div>
                <div class="btn link-over" @click="onSeatBottom('right')">
                  {{ $t("Last on the right") }}
                </div>
                <div class="search-area">
                  <input type="text" name="" v-model="searchName" />
                  <span>
                    <img
                      src="../assets/img/search-img2.png"
                      alt=""
                      @click="onSearch"
                    />
                  </span>
                </div>
              </div>
              <!-- Top main -->
              <ul class="user-list list1">
                <li>
                  <van-popover v-model:show="firstPopover" trigger="click">
                    <OrganizationSub :data="first" />
                    <!-- <div class="information">
                      <p class="label" v-show="first.userTitle !== ''">
                        {{ first.userTitle }}
                      </p>
                      <ul class="detail-list">
                        <li>{{ $t("ACCOUNT") }}：</li>
                        <li>{{ first.userName }}</li>
                      </ul>
                      <ul class="detail-list">
                        <li>{{ $t("PLAN") }}：</li>
                        <li>{{ first.stage }}</li>
                      </ul>
                      <ul class="detail-list">
                        <li>LEFT：</li>
                        <li>{{ first.performance1 }}</li>
                      </ul>
                      <ul class="detail-list">
                        <li>RIGHT：</li>
                        <li>{{ first.performance2 }}</li>
                      </ul>
                    </div> -->
                    <template #reference>
                      <div class="box">
                        <img
                          src="../assets/img/chart-user-icon-img.png"
                          alt=""
                        />
                        <!--<div>{{ first.userName }}</div>-->
                      </div>
                    </template>
                  </van-popover>
                  <!--<div class="icon link-over" v-on:click="showBox">-->
                  <!--<img src="../assets/img/chart-user-icon-img.png" alt="" />-->
                  <!--</div>-->
                  <!--<div-->
                  <!--class="hidden-detail"-->
                  <!--v-bind:class="{-->
                  <!--active: active == true,-->
                  <!--}"-->
                  <!--&gt;-->
                  <!--<p class="label">SUPER BUYSER</p>-->
                  <!--<ul class="detail-list">-->
                  <!--<li>グループ売上：</li>-->
                  <!--<li><span>1000</span>USD</li>-->
                  <!--</ul>-->
                  <!--<ul class="detail-list">-->
                  <!--<li>紹介数：</li>-->
                  <!--<li><span>10</span>USER</li>-->
                  <!--</ul>-->
                  <!--<ul class="detail-list">-->
                  <!--<li>LEFT：</li>-->
                  <!--<li><span>500</span>USD</li>-->
                  <!--</ul>-->
                  <!--<ul class="detail-list">-->
                  <!--<li>RIGHT：</li>-->
                  <!--<li><span>500</span>USD</li>-->
                  <!--</ul>-->
                  <!--</div>-->
                  <p class="name">{{ first.userName }}</p>
                  <div style="display: flex; justify-content: space-between">
                    <div
                      v-if="first.userName != Introducer"
                      @click="onBackTop(first.userName)"
                      class="down-btn link-over"
                      style="width: 45%"
                    >
                      {{ $t("Forward") }}
                    </div>
                    <div
                      v-if="first.userName != Introducer"
                      @click="onRefresh"
                      class="down-btn link-over"
                      style="width: 45%"
                    >
                      {{ $t("Reset") }}
                    </div>
                  </div>
                </li>
              </ul>

              <!-- ./Top main -->
              <div class="wrap">
                <!-- Left User -->
                <div class="left-user">
                  <ul class="user-list list2">
                    <li>
                      <van-popover
                        v-model:show="secondPopover1"
                        trigger="click"
                        placement="right"
                      >
                        <OrganizationSub :data="second1" />
                        <!-- <div class="information">
                          <p class="label">SUPER BUYSER</p>
                          <ul class="detail-list">
                            <li>アカウント：</li>
                            <li>{{ second1.userName }}</li>
                          </ul>
                          <ul class="detail-list">
                            <li>売上：</li>
                            <li>{{ second1.stage }}</li>
                          </ul>
                          <ul class="detail-list">
                            <li>ダイヤ：</li>
                            <li>{{ second1.userTitle }}</li>
                          </ul>
                          <ul class="detail-list">
                            <li>LEFT：</li>
                            <li>{{ second1.performance1 }}</li>
                          </ul>
                          <ul class="detail-list">
                            <li>RIGHT：</li>
                            <li>{{ second1.performance2 }}</li>
                          </ul>
                        </div> -->
                        <template #reference>
                          <div class="box">
                            <img
                              src="../assets/img/chart-user-icon-img.png"
                              alt=""
                            />
                          </div>
                        </template>
                      </van-popover>
                      <p class="name" v-if="second1.userName">
                        {{ second1.userName }}
                      </p>
                      <div
                        v-else
                        @click="show('1', first.userName)"
                        class="active-btn link-over"
                      >
                        {{ $t("SIGN UP") }}
                      </div>
                    </li>
                  </ul>
                  <ul class="user-list list3">
                    <li>
                      <van-popover
                        v-model:show="thirdPopover1"
                        trigger="click"
                        placement="right"
                      >
                        <OrganizationSub :data="third1" />
                        <!-- <div class="information">
                          <p class="label">SUPER BUYSER</p>
                          <ul class="detail-list">
                            <li>アカウント：</li>
                            <li>{{ third1.userName }}</li>
                          </ul>
                          <ul class="detail-list">
                            <li>売上：</li>
                            <li>{{ third1.stage }}</li>
                          </ul>
                          <ul class="detail-list">
                            <li>ダイヤ：</li>
                            <li>{{ third1.userTitle }}</li>
                          </ul>
                          <ul class="detail-list">
                            <li>LEFT：</li>
                            <li>{{ third1.performance1 }}</li>
                          </ul>
                          <ul class="detail-list">
                            <li>RIGHT：</li>
                            <li>{{ third1.performance2 }}</li>
                          </ul>
                        </div> -->
                        <template #reference>
                          <div class="box">
                            <img
                              src="../assets/img/chart-user-icon-img.png"
                              alt=""
                            />
                          </div>
                        </template>
                      </van-popover>
                      <p class="name" v-if="third1.userName">
                        {{ third1.userName }}
                      </p>
                      <div
                        v-if="third1.userName"
                        @click="onTop(third1.userName)"
                        class="down-btn link-over"
                      >
                        {{ $t("Next") }}
                      </div>
                      <div
                        v-if="second1.userName && !third1.userName"
                        @click="show('1', second1.userName)"
                        class="active-btn link-over"
                      >
                        {{ $t("SIGN UP") }}
                      </div>
                    </li>
                    <li>
                      <van-popover
                        v-model:show="thirdPopover2"
                        trigger="click"
                        placement="right"
                      >
                        <OrganizationSub :data="third2" />
                        <!-- <div class="information">
                          <p class="label">SUPER BUYSER</p>
                          <ul class="detail-list">
                            <li>アカウント：</li>
                            <li>{{ third2.userName }}</li>
                          </ul>
                          <ul class="detail-list">
                            <li>売上：</li>
                            <li>{{ third2.stage }}</li>
                          </ul>
                          <ul class="detail-list">
                            <li>ダイヤ：</li>
                            <li>{{ third2.userTitle }}</li>
                          </ul>
                          <ul class="detail-list">
                            <li>LEFT：</li>
                            <li>{{ third2.performance1 }}</li>
                          </ul>
                          <ul class="detail-list">
                            <li>RIGHT：</li>
                            <li>{{ third2.performance2 }}</li>
                          </ul>
                        </div> -->
                        <template #reference>
                          <div class="box">
                            <img
                              src="../assets/img/chart-user-icon-img.png"
                              alt=""
                            />
                          </div>
                        </template>
                      </van-popover>
                      <p class="name" v-if="third2.userName">
                        {{ third2.userName }}
                      </p>
                      <div
                        v-if="third2.userName"
                        @click="onTop(third2.userName)"
                        class="down-btn link-over"
                      >
                        {{ $t("Next") }}
                      </div>
                      <div
                        v-if="second1.userName && !third2.userName"
                        @click="show('2', second1.userName)"
                        class="active-btn link-over"
                      >
                        {{ $t("SIGN UP") }}
                      </div>
                    </li>
                  </ul>
                </div>
                <!-- ./Left User -->
                <!-- Right User -->
                <div class="right-user">
                  <ul class="user-list list2">
                    <li>
                      <van-popover
                        v-model:show="secondPopover2"
                        trigger="click"
                        placement="left"
                      >
                        <OrganizationSub :data="second2" />
                        <!-- <div class="information">
                          <p class="label">SUPER BUYSER</p>
                          <ul class="detail-list">
                            <li>アカウント：</li>
                            <li>{{ second2.userName }}</li>
                          </ul>
                          <ul class="detail-list">
                            <li>売上：</li>
                            <li>{{ second2.stage }}</li>
                          </ul>
                          <ul class="detail-list">
                            <li>ダイヤ：</li>
                            <li>{{ second2.userTitle }}</li>
                          </ul>
                          <ul class="detail-list">
                            <li>LEFT：</li>
                            <li>{{ second2.performance1 }}</li>
                          </ul>
                          <ul class="detail-list">
                            <li>RIGHT：</li>
                            <li>{{ second2.performance2 }}</li>
                          </ul>
                        </div> -->
                        <template #reference>
                          <div class="box">
                            <img
                              src="../assets/img/chart-user-icon-img.png"
                              alt=""
                            />
                          </div>
                        </template>
                      </van-popover>
                      <p class="name" v-if="second2.userName">
                        {{ second2.userName }}
                      </p>
                      <div
                        v-else
                        @click="show('2', first.userName)"
                        class="active-btn link-over"
                      >
                        {{ $t("SIGN UP") }}
                      </div>
                    </li>
                  </ul>
                  <ul class="user-list list3">
                    <li class="not-active">
                      <van-popover
                        v-model:show="thirdPopover3"
                        trigger="click"
                        placement="left"
                      >
                        <OrganizationSub :data="third3" />
                        <!-- <div class="information">
                          <p class="label">SUPER BUYSER</p>
                          <ul class="detail-list">
                            <li>アカウント：</li>
                            <li>{{ third3.userName }}</li>
                          </ul>
                          <ul class="detail-list">
                            <li>売上：</li>
                            <li>{{ third3.stage }}</li>
                          </ul>
                          <ul class="detail-list">
                            <li>ダイヤ：</li>
                            <li>{{ third3.userTitle }}</li>
                          </ul>
                          <ul class="detail-list">
                            <li>LEFT：</li>
                            <li>{{ third3.performance1 }}</li>
                          </ul>
                          <ul class="detail-list">
                            <li>RIGHT：</li>
                            <li>{{ third3.performance2 }}</li>
                          </ul>
                        </div> -->
                        <template #reference>
                          <div class="box">
                            <img
                              src="../assets/img/chart-user-icon-img.png"
                              alt=""
                            />
                          </div>
                        </template>
                      </van-popover>
                      <p class="name" v-if="third3.userName">
                        {{ third3.userName }}
                      </p>
                      <div
                        v-if="third3.userName"
                        @click="onTop(third3.userName)"
                        class="down-btn link-over"
                      >
                        {{ $t("Next") }}
                      </div>
                      <div
                        v-if="second2.userName && !third3.userName"
                        @click="show('1', second2.userName)"
                        class="active-btn link-over"
                      >
                        {{ $t("SIGN UP") }}
                      </div>
                    </li>
                    <li>
                      <van-popover
                        v-model:show="thirdPopover4"
                        trigger="click"
                        placement="left"
                      >
                        <OrganizationSub :data="third4" />
                        <!-- <div class="information">
                          <p class="label">SUPER BUYSER</p>
                          <ul class="detail-list">
                            <li>アカウント：</li>
                            <li>{{ third4.userName }}</li>
                          </ul>
                          <ul class="detail-list">
                            <li>売上：</li>
                            <li>{{ third4.stage }}</li>
                          </ul>
                          <ul class="detail-list">
                            <li>ダイヤ：</li>
                            <li>{{ third4.userTitle }}</li>
                          </ul>
                          <ul class="detail-list">
                            <li>LEFT：</li>
                            <li>{{ third4.performance1 }}</li>
                          </ul>
                          <ul class="detail-list">
                            <li>RIGHT：</li>
                            <li>{{ third4.performance2 }}</li>
                          </ul>
                        </div> -->
                        <template #reference>
                          <div class="box">
                            <img
                              src="../assets/img/chart-user-icon-img.png"
                              alt=""
                            />
                          </div>
                        </template>
                      </van-popover>
                      <p class="name" v-if="third4.userName">
                        {{ third4.userName }}
                      </p>
                      <div
                        v-if="third4.userName"
                        @click="onTop(third4.userName)"
                        class="down-btn link-over"
                      >
                        {{ $t("Next") }}
                      </div>
                      <div
                        v-if="second2.userName && !third4.userName"
                        @click="show('2', second2.userName)"
                        class="active-btn link-over"
                      >
                        {{ $t("SIGN UP") }}
                      </div>
                    </li>
                  </ul>
                </div>
                <!-- ./Right User -->
              </div>
            </form>
          </div>
          <div class="detail-history">
            <h2>
              {{ $t("Referral history") }}
              <router-link to="./referral" class="link-over">{{
                $t("Details")
              }}</router-link>
            </h2>
            <table>
              <tr>
                <th>{{ $t("DATE") }}</th>
                <th>{{ $t("USER NAME") }}</th>
                <!-- <th>ポジション</th> -->
              </tr>
              <template v-if="tableList.length > 0">
                <tr v-for="(item, index) in tableList" :key="index">
                  <td>{{ item.createdTime }}</td>
                  <td>{{ item.userName }}</td>
                </tr>
              </template>
              <tr v-else>
                <td colspan="4">{{ $t("NO DATA") }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- /.contents -->
    <van-popup v-model:show="showRef" style="width: 90%">
      <div class="modal-box">
        <div class="modal-ttl">
          <p>{{ $t("REFERRAL CODE") }}</p>
        </div>
        <div class="code-area">
          <p>{{ $t("REFERRAL URL") }}</p>
          <div class="input-area">
            <input type="text" id="url" v-model="referralUrl" readonly />
          </div>
          <span
            class="copy-btn link-over"
            v-clipboard:copy="referralUrl"
            v-clipboard:success="urlCopy"
            v-clipboard:error="urlError"
            >{{ $t("COPY") }}</span
          >
        </div>
        <div class="btn">
          <button v-on:click="hide" type="button" class="link-over">
            {{ $t("Close") }}
          </button>
        </div>
      </div>
    </van-popup>
    <van-overlay :show="loading">
      <div class="loading-position" @click.stop>
        <van-loading type="spinner" color="#1989fa" />
      </div>
    </van-overlay>
    <Footer title="Organization" />
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import HeaderMenu from "@/components/HeaderMenu.vue";
import Sidebar from "@/components/Sidebar.vue";
import Footer from "@/components/Footer.vue";
import Account from "@/js/api/account";
import LoginUser from "@/js/loginUser";
import Common from "@/js/common";
import { Notify, Dialog } from "vant";
import OrganizationSub from "@/components/OrganizationSub.vue";
export default {
  name: "Organization",
  components: {
    Header,
    HeaderMenu,
    Sidebar,
    Footer,
    OrganizationSub,
  },
  data: () => {
    return {
      title: "Organization",
      active: false,
      showRef: false,
      referralUrl: "",
      firstPopover: false,
      secondPopover1: false,
      secondPopover2: false,
      thirdPopover1: false,
      thirdPopover2: false,
      thirdPopover3: false,
      thirdPopover4: false,
      loading: false,
      searchName: "",
      Introducer: "",
      first: {
        userName: "",
        stage: "",
        userTitle: "",
        performance1: "",
        performance2: "",
        stageIndex: "",
      },
      second1: {
        userName: "",
        stage: "",
        userTitle: "",
        performance1: "",
        performance2: "",
        stageIndex: "",
      },
      second2: {
        userName: "",
        stage: "",
        userTitle: "",
        performance1: "",
        performance2: "",
        stageIndex: "",
      },
      third1: {
        userName: "",
        stage: "",
        userTitle: "",
        performance1: "",
        performance2: "",
        stageIndex: "",
      },
      third2: {
        userName: "",
        stage: "",
        userTitle: "",
        performance1: "",
        performance2: "",
        stageIndex: "",
      },
      third3: {
        userName: "",
        stage: "",
        userTitle: "",
        performance1: "",
        performance2: "",
        stageIndex: "",
      },
      third4: {
        userName: "",
        stage: "",
        userTitle: "",
        performance1: "",
        performance2: "",
        stageIndex: "",
      },
      currentPage: 1,
      total: 0,
      tableList: [],
    };
  },
  created() {
    this.listDownLines();
  },
  computed: {},
  mounted: function () {
    this.init();
    if (LoginUser.getUserInfo()) {
      if (this.$route.params.topName) {
        this.first.userName = this.$route.params.topName;
      } else {
        this.first.userName = LoginUser.getUserInfo().userName;
      }
      this.Introducer = LoginUser.getUserInfo().userName;
    }
    this.getListSeatUser();
  },
  methods: {
    init() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", vh + "px");

      var ua = navigator.userAgent.toLowerCase();
      var isiOS = ua.indexOf("iphone") > -1 || ua.indexOf("ipad") > -1;
      if (isiOS) {
        var viewport = document.querySelector('meta[name="viewport"]');
        if (viewport) {
          var viewportContent = viewport.getAttribute("content");
          viewport.setAttribute(
            "content",
            viewportContent + ", user-scalable=no"
          );
        }
      }
    },
    getListSeatUser() {
      this.loading = true;

      Account.getListSeatUser(this.first.userName)
        .then((res) => {
          if (res) {
            this.loading = false;
            this.onReset();
            this.first.stage = res.data.stage;
            this.first.userTitle = res.data.userTitle;
            this.first.performance1 = res.data.performance1;
            this.first.performance2 = res.data.performance2;
            this.first.stageIndex = res.data.stageIndex;
            if (res.data.seatUsers.length > 0) {
              this.seatUsersFoeEch(res.data.seatUsers, "1");
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    toggleAction() {
      this.active = !this.active;
    },
    showBox: function () {
      this.active = true;
      setTimeout(() => {
        this.active = false;
      }, 5000);
    },
    show(id, val) {
      this.referralUrl =
        Common.hostUrl +
        "/#/Signup?SeatNo=" +
        id +
        "&SeatUser=" +
        val +
        "&Introducer=" +
        this.Introducer;
      this.showRef = true;
    },
    hide: function () {
      this.showRef = false;
    },
    urlCopy: function () {
      Notify({ type: "success", message: this.$t("Copy successfully") });
    },
    urlError: function () {
      Notify({ type: "danger", message: this.$t("Copy failed") });
    },
    onReset() {
      this.first.stage = "";
      this.first.userTitle = "";
      this.first.performance1 = "";
      this.first.performance2 = "";
      this.second1.userName = "";
      this.second1.stage = "";
      this.second1.userTitle = "";
      this.second1.performance1 = "";
      this.second1.performance2 = "";
      this.second2.userName = "";
      this.second2.stage = "";
      this.second2.userTitle = "";
      this.second2.performance1 = "";
      this.second2.performance2 = "";
      this.third1.userName = "";
      this.third1.stage = "";
      this.third1.userTitle = "";
      this.third1.performance1 = "";
      this.third1.performance2 = "";
      this.third2.userName = "";
      this.third2.stage = "";
      this.third2.userTitle = "";
      this.third2.performance1 = "";
      this.third2.performance2 = "";
      this.third3.userName = "";
      this.third3.stage = "";
      this.third3.userTitle = "";
      this.third3.performance1 = "";
      this.third3.performance2 = "";
      this.third4.userName = "";
      this.third4.stage = "";
      this.third4.userTitle = "";
      this.third4.performance1 = "";
      this.third4.performance2 = "";
    },
    onSeatBottom(val) {
      this.onReset();
      this.loading = true;
      Account.seatBottom(val)
        .then((res) => {
          if (res) {
            this.loading = false;
            this.first.stage = res.data.stage;
            this.first.userTitle = res.data.userTitle;
            this.first.performance1 = res.data.performance1;
            this.first.performance2 = res.data.performance2;
            this.first.stageIndex = res.data.stageIndex;
            this.first.userName = res.data.userName;
            if (res.data.seatUsers.length > 0) {
              this.seatUsersFoeEch(res.data.seatUsers, "1");
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onSearch() {
      this.loading = true;
      Account.getListSeatUser(this.searchName)
        .then((res) => {
          if (res) {
            this.loading = false;
            this.onReset();
            this.first.userName = this.searchName;
            this.first.stage = res.data.stage;
            this.first.userTitle = res.data.userTitle;
            this.first.performance1 = res.data.performance1;
            this.first.performance2 = res.data.performance2;
            this.first.stageIndex = res.data.stageIndex;
            if (res.data.seatUsers.length > 0) {
              this.seatUsersFoeEch(res.data.seatUsers, "1");
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    seatUsersFoeEch(data, index, val) {
      if (index == "1") {
        data.forEach((item) => {
          if (item.seatNo == "1") {
            this.second1.userName = item.userName;
            this.second1.stage = item.stage;
            this.second1.userTitle = item.userTitle;
            this.second1.performance1 = item.performance1;
            this.second1.performance2 = item.performance2;
            this.second1.stageIndex = item.stageIndex;
            if (item.seatUsers.length > 0) {
              this.seatUsersFoeEch(item.seatUsers, "2", "1");
            }
          } else if (item.seatNo == "2") {
            this.second2.userName = item.userName;
            this.second2.stage = item.stage;
            this.second2.userTitle = item.userTitle;
            this.second2.performance1 = item.performance1;
            this.second2.performance2 = item.performance2;
            this.second2.stageIndex = item.stageIndex;
            if (item.seatUsers.length > 0) {
              this.seatUsersFoeEch(item.seatUsers, "2", "2");
            }
          }
        });
      } else if (index == "2") {
        if (val == "1") {
          data.forEach((item) => {
            if (item.seatNo == "1") {
              this.third1.userName = item.userName;
              this.third1.stage = item.stage;
              this.third1.userTitle = item.userTitle;
              this.third1.performance1 = item.performance1;
              this.third1.performance2 = item.performance2;
              this.third1.stageIndex = item.stageIndex;
            } else if (item.seatNo == "2") {
              this.third2.userName = item.userName;
              this.third2.stage = item.stage;
              this.third2.userTitle = item.userTitle;
              this.third2.performance1 = item.performance1;
              this.third2.performance2 = item.performance2;
              this.third2.stageIndex = item.stageIndex;
            }
          });
        } else if (val == "2") {
          data.forEach((item) => {
            if (item.seatNo == "1") {
              this.third3.userName = item.userName;
              this.third3.stage = item.stage;
              this.third3.userTitle = item.userTitle;
              this.third3.performance1 = item.performance1;
              this.third3.performance2 = item.performance2;
              this.third3.stageIndex = item.stageIndex;
            } else if (item.seatNo == "2") {
              this.third4.userName = item.userName;
              this.third4.stage = item.stage;
              this.third4.userTitle = item.userTitle;
              this.third4.performance1 = item.performance1;
              this.third4.performance2 = item.performance2;
              this.third4.stageIndex = item.stageIndex;
            }
          });
        }
      }
    },
    onRefresh() {
      let _this = this;
      Dialog.confirm({
        message: _this.$t("Do you want to reset it?"),
      })
        .then(() => {
          _this.first.userName = _this.Introducer;
          _this.onReset();
          _this.getListSeatUser();
        })
        .catch(() => {
          // on cancel
        });
    },
    onTop(val) {
      this.first.userName = val;
      this.onReset();
      this.getListSeatUser();
    },
    onBackTop(val) {
      this.loading = true;
      Account.upLevel(val)
        .then((res) => {
          if (res) {
            this.loading = false;
            this.onReset();
            this.first.stage = res.data.stage;
            this.first.userName = res.data.userName;
            this.first.userTitle = res.data.userTitle;
            this.first.performance1 = res.data.performance1;
            this.first.performance2 = res.data.performance2;
            this.first.stageIndex = res.data.stageIndex;
            if (res.data.seatUsers.length > 0) {
              this.seatUsersFoeEch(res.data.seatUsers, "1");
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onPage(val) {
      this.currentPage = val;
      this.listDownLines();
    },
    listDownLines() {
      Account.listDownLines(this.currentPage - 1, 4).then((res) => {
        if (res) {
          this.tableList = res.data.detail;
          this.total = res.data.total;
        }
      });
    },
  },
};
</script>
<style scoped lang="less">
:deep(.van-popover__wrapper) {
  display: block;
}
.box {
  text-align: center;
}
.information {
  background-color: #fff;
  padding: 6px;
  border-radius: 6px;
  width: 160px;
  .label {
    color: #1105ff;
    font-size: 10px;
    font-weight: 600;
    display: inline-block;
    border: 2px solid #1105ff;
    border-radius: 6px;
    padding: 4px 18px;
    margin: 0 10px;
    text-align: center;
  }
  .detail-list {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 600;
    margin: 10px;
    word-break: break-all;
    li:first-child {
      padding-right: 4px;
      width: 60%;
    }
    li:last-child {
      width: 40%;
      font-weight: 400;
      font-size: 12px;
      text-align: right;
      /*span{*/
      /*font-size: 12px;*/
      /*display: inline-block;*/
      /*margin-right: 4px;*/
      /*}*/
    }
  }
}
.loading-position {
  position: absolute;
  left: calc(50% - 15px);
  top: calc(50% - 15px);
}
</style>
