<template>
  <div class="wrapper plan-page">
    <!-- Header -->
    <Header />
    <!-- HeaderMenu -->
    <HeaderMenu />

    <div class="contents">
      <div class="layout">
        <div class="left">
          <div class="pc-only">
            <Sidebar />
          </div>
        </div>
        <div class="right">
          <h1>{{ $t("PLAN") }}</h1>
          <div class="tab-area">
            <div
              class="tab link-over"
              v-on:click="isSelect('Business')"
              v-bind:class="{ active: isActive === 'Business' }"
            >
              BUSINESS
            </div>
            <div
              class="tab link-over"
              v-on:click="isSelect('Investor')"
              v-bind:class="{ active: isActive === 'Investor' }"
            >
              INVESTOR
            </div>
          </div>
          <div class="balance">
            <h2>{{ $t("Current staking") }}</h2>
            <div class="total-amount">
              <span>{{ plateInfo.fp }}</span> FP
            </div>
          </div>
          <div class="tab-contents" v-show="isActive === 'Business'">
            <div class="form">
              <form action="">
                <div class="input-area">
                  <input
                    type="radio"
                    name="plan"
                    id="plan1"
                    :disabled="plateInfo.pp >= 500"
                  />
                  <label
                    for="plan1"
                    class="link-over bronze"
                    @click="selectFP('500')"
                  >
                    {{ $t("BRONZE") }}<span>$ 500</span>
                    <div
                      class="circle"
                      :class="{ 'disabled-bg': plateInfo.pp >= 500 }"
                    >
                      <img src="../assets/img/check-icon-img.png" />
                    </div>
                  </label>
                </div>
                <div class="input-area">
                  <input
                    type="radio"
                    name="plan"
                    id="plan2"
                    :disabled="plateInfo.pp >= 2000"
                  />
                  <label
                    for="plan2"
                    class="link-over silver"
                    @click="selectFP('2000')"
                  >
                    {{ $t("SILVER") }}<span>$ 2000</span>
                    <div
                      class="circle"
                      :class="{ 'disabled-bg': plateInfo.pp >= 2000 }"
                    >
                      <img src="../assets/img/check-icon-img.png" />
                    </div>
                  </label>
                </div>
                <div class="input-area">
                  <input
                    type="radio"
                    name="plan"
                    id="plan3"
                    :disabled="plateInfo.pp >= 10000"
                  />
                  <label
                    for="plan3"
                    class="link-over gold"
                    @click="selectFP('10000')"
                  >
                    {{ $t("GOLD") }}<span>$ 10000</span>
                    <div
                      class="circle"
                      :class="{ 'disabled-bg': plateInfo.pp >= 10000 }"
                    >
                      <img src="../assets/img/check-icon-img.png" />
                    </div>
                  </label>
                </div>
                <div class="input-area">
                  <input
                    type="radio"
                    name="plan"
                    id="plan4"
                    :disabled="plateInfo.pp >= 30000"
                  />
                  <label
                    for="plan4"
                    class="link-over ruby"
                    @click="selectFP('30000')"
                  >
                    {{ $t("RUBY") }}<span>$ 30000</span>
                    <div
                      class="circle"
                      :class="{ 'disabled-bg': plateInfo.pp >= 30000 }"
                    >
                      <img src="../assets/img/check-icon-img.png" />
                    </div>
                  </label>
                </div>
                <div class="input-area">
                  <input
                    type="radio"
                    name="plan"
                    id="plan5"
                    :disabled="plateInfo.pp >= 50000"
                  />
                  <label
                    for="plan5"
                    class="link-over sapphire"
                    @click="selectFP('50000')"
                  >
                    {{ $t("SAPPHIRE") }}<span>$ 50000</span>
                    <div
                      class="circle"
                      :class="{ 'disabled-bg': plateInfo.pp >= 50000 }"
                    >
                      <img src="../assets/img/check-icon-img.png" />
                    </div>
                  </label>
                </div>
                <div class="input-area">
                  <input
                    type="radio"
                    name="plan"
                    id="plan6"
                    :disabled="plateInfo.pp >= 100000"
                  />
                  <label
                    for="plan6"
                    class="link-over emerald"
                    @click="selectFP('100000')"
                  >
                    {{ $t("EMERALD") }}<span>$ 100000</span>
                    <div
                      class="circle"
                      :class="{ 'disabled-bg': plateInfo.pp >= 100000 }"
                    >
                      <img src="../assets/img/check-icon-img.png" />
                    </div>
                  </label>
                </div>
                <div class="input-area">
                  <input
                    type="radio"
                    name="plan"
                    id="plan7"
                    :disabled="plateInfo.pp >= 500000"
                  />
                  <label
                    for="plan7"
                    class="link-over diamond"
                    @click="selectFP('500000')"
                  >
                    {{ $t("DIAMOND") }}<span>$ 500000</span>
                    <div
                      class="circle"
                      :class="{ 'disabled-bg': plateInfo.pp >= 500000 }"
                    >
                      <img src="../assets/img/check-icon-img.png" />
                    </div>
                  </label>
                </div>
                <div class="input-area">
                  <input type="radio" name="plan" id="plan15" />
                  <label for="plan15" class="link-over other">
                    カスタムプラン
                    <div
                      class="circle"
                      v-bind:class="{
                        active: hasPlanAmount,
                      }"
                    >
                      <img src="../assets/img/check-icon-img.png" />
                    </div>
                    <div class="hidden-form">
                      <input
                        type="text"
                        name=""
                        v-bind="amountPlan"
                        v-model="customAmount"
                        placeholder="購入額を入力"
                      />
                    </div>
                  </label>
                </div>
                <div class="form-btn">
                  <button
                    type="button"
                    class="link-over"
                    v-on:click="show2"
                    :disabled="customAmount < 200"
                  >
                    {{ $t("To buy") }}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="tab-contents" v-show="isActive === 'Investor'">
            <div class="form">
              <form action="">
                <div class="input-area">
                  <input
                    type="radio"
                    name="plan"
                    id="plan8"
                    :disabled="plateInfo.pp >= 500"
                  />
                  <label
                    for="plan8"
                    class="link-over bronze"
                    @click="selectFP('500')"
                  >
                    {{ $t("BRONZE") }}<span>$ 500</span>
                    <div
                      class="circle"
                      :class="{ 'disabled-bg': plateInfo.pp >= 500 }"
                    >
                      <img src="../assets/img/check-icon-img.png" />
                    </div>
                  </label>
                </div>
                <div class="input-area">
                  <input
                    type="radio"
                    name="plan"
                    id="plan9"
                    :disabled="plateInfo.pp >= 2000"
                  />
                  <label
                    for="plan9"
                    class="link-over silver"
                    @click="selectFP('2000')"
                  >
                    {{ $t("SILVER") }}<span>$ 2000</span>
                    <div
                      class="circle"
                      :class="{ 'disabled-bg': plateInfo.pp >= 2000 }"
                    >
                      <img src="../assets/img/check-icon-img.png" />
                    </div>
                  </label>
                </div>
                <div class="input-area">
                  <input
                    type="radio"
                    name="plan"
                    id="plan10"
                    :disabled="plateInfo.pp >= 10000"
                  />
                  <label
                    for="plan10"
                    class="link-over gold"
                    @click="selectFP('10000')"
                  >
                    {{ $t("GOLD") }}<span>$ 10000</span>
                    <div
                      class="circle"
                      :class="{ 'disabled-bg': plateInfo.pp >= 10000 }"
                    >
                      <img src="../assets/img/check-icon-img.png" />
                    </div>
                  </label>
                </div>
                <div class="input-area">
                  <input
                    type="radio"
                    name="plan"
                    id="plan11"
                    :disabled="plateInfo.pp >= 30000"
                  />
                  <label
                    for="plan11"
                    class="link-over ruby"
                    @click="selectFP('30000')"
                  >
                    {{ $t("RUBY") }}<span>$ 30000</span>
                    <div
                      class="circle"
                      :class="{ 'disabled-bg': plateInfo.pp >= 30000 }"
                    >
                      <img src="../assets/img/check-icon-img.png" />
                    </div>
                  </label>
                </div>
                <div class="input-area">
                  <input
                    type="radio"
                    name="plan"
                    id="plan12"
                    :disabled="plateInfo.pp >= 50000"
                  />
                  <label
                    for="plan12"
                    class="link-over sapphire"
                    @click="selectFP('50000')"
                  >
                    {{ $t("SAPPHIRE") }}<span>$ 50000</span>
                    <div
                      class="circle"
                      :class="{ 'disabled-bg': plateInfo.pp >= 50000 }"
                    >
                      <img src="../assets/img/check-icon-img.png" />
                    </div>
                  </label>
                </div>
                <div class="input-area">
                  <input
                    type="radio"
                    name="plan"
                    id="plan13"
                    :disabled="plateInfo.pp >= 100000"
                  />
                  <label
                    for="plan13"
                    class="link-over emerald"
                    @click="selectFP('100000')"
                  >
                    {{ $t("EMERALD") }}<span>$ 100000</span>
                    <div
                      class="circle"
                      :class="{ 'disabled-bg': plateInfo.pp >= 100000 }"
                    >
                      <img src="../assets/img/check-icon-img.png" />
                    </div>
                  </label>
                </div>
                <div class="input-area">
                  <input
                    type="radio"
                    name="plan"
                    id="plan14"
                    :disabled="plateInfo.pp >= 500000"
                  />
                  <label
                    for="plan14"
                    class="link-over diamond"
                    @click="selectFP('500000')"
                  >
                    {{ $t("DIAMOND") }}<span>$ 500000</span>
                    <div
                      class="circle"
                      :class="{ 'disabled-bg': plateInfo.pp >= 500000 }"
                    >
                      <img src="../assets/img/check-icon-img.png" />
                    </div>
                  </label>
                </div>
                <div class="input-area">
                  <input type="radio" name="plan" id="plan16" />
                  <label for="plan16" class="link-over other">
                    {{ $t("Custom plan") }}
                    <div class="circle">
                      <img src="../assets/img/check-icon-img.png" />
                    </div>
                    <div class="hidden-form">
                      <input
                        type="number"
                        step="1"
                        name=""
                        v-model="customAmount"
                        :placeholder="$t('Enter the purchase amount')"
                      />
                    </div>
                  </label>
                </div>
                <div class="form-btn">
                  <button
                    type="button"
                    class="link-over"
                    v-on:click="show2"
                    :disabled="customAmount < 200"
                  >
                    {{ $t("To buy") }}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="table">
            <h2>
              {{ $t("PLAN HISTORY") }}
              <router-link to="./history" class="link-over">{{
                $t("Details")
              }}</router-link>
            </h2>
            <div class="tab-area">
              <div
                class="tab link-over"
                v-on:click="isSelect2('Business')"
                v-bind:class="{ active: isActive2 === 'Business' }"
              >
                BUSINESS
              </div>
              <div
                class="tab link-over"
                v-on:click="isSelect2('Investor')"
                v-bind:class="{ active: isActive2 === 'Investor' }"
              >
                INVESTOR
              </div>
            </div>
            <div class="table-wrap" v-show="isActive2 === 'Business'">
              <table>
                <tr>
                  <th>{{ $t("DATE") }}</th>
                  <th>{{ $t("CURRENCY") }}</th>
                  <th>{{ $t("PLAN") }}</th>
                  <th>{{ $t("POINT AMOUNT") }}</th>
                </tr>
                <template v-if="activeList.length > 0">
                  <tr v-for="(item, index) in activeList" :key="index">
                    <td>{{ item.createdTime }}</td>
                    <td>{{ item.currency }}</td>
                    <td>{{ item.bonusSettingText }}</td>
                    <td style="text-align: right">
                      {{ item.pointsText }}&nbsp;
                    </td>
                  </tr>
                </template>
                <tr v-else>
                  <td colspan="4">{{ $t("NO DATA") }}</td>
                </tr>
              </table>
            </div>
            <div class="table-wrap" v-show="isActive2 === 'Investor'">
              <table>
                <tr>
                  <th>{{ $t("DATE") }}</th>
                  <th>{{ $t("CURRENCY") }}</th>
                  <th>{{ $t("PLAN") }}</th>
                  <th>{{ $t("POINT AMOUNT") }}</th>
                </tr>
                <template v-if="activeList.length > 0">
                  <tr v-for="(item, index) in activeList" :key="index">
                    <td>{{ item.createdTime }}</td>
                    <td>{{ item.currency }}</td>
                    <td>{{ item.bonusSettingText }}</td>
                    <td style="text-align: right">
                      {{ item.pointsText }}&nbsp;
                    </td>
                  </tr>
                </template>
                <tr v-else>
                  <td colspan="4">{{ $t("NO DATA") }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.contents -->
    <van-popup v-model:show="showRef2" style="width: 90%">
      <div class="modal-box">
        <div class="modal-ttl">
          <h2>{{ $t("Select a purchase method") }}</h2>
          <div class="modal-form">
            <form action="">
              <div class="input-area">
                <p class="error-txt" v-show="balanceAP">
                  {{ $t("Not enough balance.") }}
                </p>
                <input type="radio" name="currency" id="currency1" />
                <label
                  for="currency1"
                  class="link-over"
                  :class="{ not: balanceAP }"
                  @click="selectBalance('AP')"
                >
                  USD<span>{{ $t("BALANCE") }}：{{ plateInfo.ap }} USD</span>
                  <div class="circle">
                    <img src="../assets/img/check-icon-img.png" />
                  </div>
                </label>
              </div>
              <div class="input-area">
                <p class="error-txt" v-show="balanceRP">
                  {{ $t("Not enough balance.") }}
                </p>
                <input type="radio" name="currency" id="currency2" />
                <label
                  for="currency2"
                  class="link-over"
                  :class="{ not: balanceRP }"
                  @click="selectBalance('RP')"
                >
                  RP<span>{{ $t("BALANCE") }}：{{ plateInfo.rp }} RP</span>
                  <div class="circle">
                    <img src="../assets/img/check-icon-img.png" />
                  </div>
                </label>
              </div>
              <div class="input-area">
                <p class="error-txt" v-show="balanceSP">
                  {{ $t("Not enough balance.") }}
                </p>
                <input type="radio" name="currency" id="currency3" />
                <label
                  for="currency3"
                  class="link-over"
                  :class="{ not: balanceSP }"
                  @click="selectBalance('SP')"
                >
                  PP<span>{{ $t("BALANCE") }}：{{ plateInfo.sp }} PP</span>
                  <div class="circle">
                    <img src="../assets/img/check-icon-img.png" />
                  </div>
                </label>
              </div>
              <div class="form-btn">
                <button
                  type="button"
                  class="link-over"
                  v-on:click="isModal"
                  :disabled="balanceAP && balanceRP && balanceSP && assetName"
                >
                  {{ $t("To buy") }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </van-popup>
    <van-popup v-model:show="showRef1" style="width: 90%">
      <div class="modal-box">
        <div class="modal-ttl">
          <p>
            {{ rankName }} {{ $t("Buy a plan") }}<br class="sp-only" />{{
              $t("Is it OK?")
            }}
          </p>
        </div>
        <div class="btn">
          <button
            v-on:click="hide"
            type="button"
            class="link-over"
            :disabled="isSubmit"
          >
            {{ $t("To buy") }}
          </button>
        </div>
      </div>
    </van-popup>
    <Footer :title="$t('PLAN')" />
  </div>
</template>
<style scoped></style>
<script>
import Header from "@/components/Header.vue";
import HeaderMenu from "@/components/HeaderMenu.vue";
import Sidebar from "@/components/Sidebar.vue";
import Footer from "@/components/Footer.vue";
import Plate from "@/js/api/plate";
import { Notify } from "vant";
import Asset from "@/js/api/asset.js";

export default {
  name: "Plan",
  components: {
    Header,
    HeaderMenu,
    Sidebar,
    Footer,
  },
  data: () => {
    return {
      title: "Plan",
      isActive: "Business",
      isActive2: "Business",
      amountPlan: "",
      amountPlate: "",
      customAmount: "",
      assetName: "",
      deduction: "",
      entryPlayType: "Business",
      showRef1: false,
      showRef2: false,
      plateInfo: {
        ap: 0,
        fp: 0,
        rp: 0,
        sp: 0,
        pp: 0,
        entryPlayType: "",
      },
      activeList: [],
      rankName: "",
      isSubmit: false,
    };
  },
  watch: {
    "$store.state.plateInfo.ap": function () {
      this.getPlateInfo();
    },
    customAmount: function (value) {
      this.customAmount = Number(value).toFixed(0);
      this.amountPlate = Number(this.customAmount) + Number(this.plateInfo.pp);
    },
  },
  created() {
    this.getHistories();
  },
  computed: {
    hasPlanAmount() {
      return this.amountPlan;
    },
    balanceAP() {
      let num = this.amountPlate - this.plateInfo.pp;
      if (num > this.plateInfo.ap) {
        return true;
      } else {
        return false;
      }
    },
    balanceRP() {
      let num = this.amountPlate - this.plateInfo.pp;
      if (num > this.plateInfo.rp) {
        return true;
      } else {
        return false;
      }
    },
    balanceSP() {
      let num = this.amountPlate - this.plateInfo.pp;
      if (num > this.plateInfo.sp) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted: function () {
    this.init();
    this.getPlateInfo();
  },
  methods: {
    init() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", vh + "px");

      var ua = navigator.userAgent.toLowerCase();
      var isiOS = ua.indexOf("iphone") > -1 || ua.indexOf("ipad") > -1;
      if (isiOS) {
        var viewport = document.querySelector('meta[name="viewport"]');
        if (viewport) {
          var viewportContent = viewport.getAttribute("content");
          viewport.setAttribute(
            "content",
            viewportContent + ", user-scalable=no"
          );
        }
      }
    },
    getPlateInfo() {
      this.plateInfo.ap = this.$store.getters.getPlateInfo.ap;
      this.plateInfo.fp = this.$store.getters.getPlateInfo.fp;
      this.plateInfo.rp = this.$store.getters.getPlateInfo.rp;
      this.plateInfo.sp = this.$store.getters.getPlateInfo.sp;
      this.plateInfo.pp = this.$store.getters.getPlateInfo.pp;
      this.plateInfo.entryPlayType =
        this.$store.getters.getPlateInfo.entryPlayType;
      if (this.$store.getters.getPlateInfo.entryPlayType) {
        this.isActive = this.$store.getters.getPlateInfo.entryPlayType;
        this.isActive2 = this.$store.getters.getPlateInfo.entryPlayType;
        this.entryPlayType = this.$store.getters.getPlateInfo.entryPlayType;
      }
    },
    selectFP(num) {
      if (Number(this.plateInfo.pp) < num) {
        this.amountPlate = num;
        this.customAmount = this.amountPlate - this.plateInfo.pp;
      } else {
        this.amountPlate = "";
        this.customAmount = "";
      }
    },
    selectBalance(name) {
      this.deduction = this.amountPlate - this.plateInfo.pp;
      this.assetName = name;
    },
    isSelect: function (num) {
      if (!this.plateInfo.entryPlayType) {
        this.isActive = num;
        this.entryPlayType = num;
        this.getHistories();
      }
    },
    isSelect2: function (num) {
      if (!this.plateInfo.entryPlayType) {
        this.isActive2 = num;
        this.entryPlayType = num;
      }
    },
    isModal: function () {
      if (Number(this.amountPlate) >= 500000) {
        this.rankName = this.$t("DIAMOND");
      } else if (Number(this.amountPlate) >= 100000) {
        this.rankName = this.$t("EMERALD");
      } else if (Number(this.amountPlate) >= 50000) {
        this.rankName = this.$t("SAPPHIRE");
      } else if (Number(this.amountPlate) >= 30000) {
        this.rankName = this.$t("RUBY");
      } else if (Number(this.amountPlate) >= 10000) {
        this.rankName = this.$t("GOLD");
      } else if (Number(this.amountPlate) >= 2000) {
        this.rankName = this.$t("SILVER");
      } else if (Number(this.amountPlate) >= 500) {
        this.rankName = this.$t("BRONZE");
      }
      this.showRef1 = true;
      this.showRef2 = false;
    },

    hide: function () {
      if (!this.isSubmit) {
        this.isSubmit = true;

        if (!this.balanceAP || !this.balanceRP || !this.balanceSP) {
          if (this.assetName) {
            let data = {
              entryPlayType: this.entryPlayType,
              assetName: this.assetName,
              deduction: this.deduction,
            };
            Plate.activePlate(data)
              .then((res) => {
                if (res) {
                  Notify({ type: "success", message: "Success" });
                }
                this.showRef1 = false;

                setTimeout(() => {
                  window.location.reload();
                }, 2000);
              })
              .finally(() => {
                this.isSubmit = false;
              });
          }
        }
      }
    },
    show2: function () {
      // this.$modal.show("planModal2");
      if (this.plateInfo.entryPlayType) {
        if (this.amountPlate) {
          this.showRef2 = true;
          this.assetName = "";
          this.deduction = "";
        }
      } else {
        if (this.amountPlate >= 500) {
          this.showRef2 = true;
          this.assetName = "";
          this.deduction = "";
        } else {
          Notify({ type: "danger", message: "500以上の購入金額" });
        }
      }
    },
    hide2: function () {
      //this.$modal.hide("planModal2");
      this.showRef2 = false;
    },
    getHistories() {
      let data = {
        index: 0,
        take: 5,
        entryPlayType: this.isActive,
        historyType: "PlateActive",
      };
      Asset.getHistories(data).then((res) => {
        if (res) {
          this.activeList = res.data.detail;
        }
      });
    },
  },
};
</script>
<style scoped lang="less">
.disabled-bg {
  background-color: #999 !important;
}
:disabled {
  opacity: 0.4 !important;
}
</style>
