<template>
  <div class="wrapper staking-page">
    <!-- Header -->
    <Header />
    <!-- HeaderMenu -->
    <HeaderMenu />

    <div class="contents">
      <div class="layout">
        <div class="left">
          <div class="pc-only">
            <Sidebar />
          </div>
        </div>
        <div class="right">
          <h1>{{ $t("RE-STAKING") }}</h1>
          <div class="balance">
            <h2>{{ $t("Staking amount") }}</h2>
            <div class="total-amount">
              <span>{{ Number(plateInfo.totalFp).toFixed(3) }}</span> FP
            </div>
            <ul class="list">
              <li>
                <h3>FP</h3>
                <div class="amount">
                  <span>{{ Number(plateInfo.fp).toFixed(3) }}</span>
                </div>
              </li>
              <li>
                <h3>{{ $t("FP Reward") }}</h3>
                <div class="amount">
                  <span>{{ Number(plateInfo.fp2).toFixed(3) }}</span>
                </div>
              </li>
            </ul>
          </div>
          <div class="form">
            <form>
              <!-- <div class="input-area">
                <label class="label" for="amount">
                  <input
                    type="text"
                    name=""
                    id="amount"
                    placeholder=" "
                    v-model.number="amountBtc"
                  />
                  <span class="group-label">ステーキング額（FP）</span>
                  <span class="upper-amount">(0.00000111 BTC)</span>
                </label>
                <span class="circle" v-bind:class="{ done: hasBtcAmount }">
                  <span class="bar"></span>
                </span>
              </div> -->
              <div class="wrap">
                <div class="label-area">
                  <input type="radio" name="plan" id="plan1" checked />
                  <label for="plan1" class="link-over">
                    100 {{ $t("days")
                    }}<span>（{{ $t("Interest rate") }} 1.5%）</span>
                    <div class="circle">
                      <img src="../assets/img/check-icon-img.png" />
                    </div>
                  </label>
                </div>
                <div class="notice">
                  {{
                    $t(
                      "※In case of re-staking, the full amount will be staked."
                    )
                  }}
                </div>
              </div>
              <div class="btn">
                <button
                  type="button"
                  class="link-over"
                  id="send_btn"
                  v-on:click="show"
                >
                  {{ $t("STAKING") }}
                </button>
              </div>
            </form>
          </div>
          <!-- <div class="table">
            <h2>{{ $t("Withdrawal history") }}</h2>
            <table>
              <tr>
                <th>{{ $t("Date") }}</th>
                <th>{{ $t("Type") }}</th>
                <th>{{ $t("Amount") }}</th>
                <th>{{ $t("Status") }}</th>
              </tr>
              <tr>
                <td>2020.04.10<br />12:23:32</td>
                <td>BTC</td>
                <td>0.01BTC</td>
                <td>{{ $t("Not approved") }}</td>
              </tr>
              <tr>
                <td>2020.04.10<br />12:23:32</td>
                <td>BTC</td>
                <td>0.01BTC</td>
                <td>{{ $t("Approved") }}</td>
              </tr>
              <tr>
                <td>2020.04.10<br />12:23:32</td>
                <td>BTC</td>
                <td>0.01BTC</td>
                <td>{{ $t("Not approved") }}</td>
              </tr>
              <tr>
                <td>2020.04.10<br />12:23:32</td>
                <td>BTC</td>
                <td>0.01BTC</td>
                <td>{{ $t("Approved") }}</td>
              </tr>
            </table>
          </div> -->
        </div>
      </div>
    </div>
    <!-- /.contents -->
    <van-dialog
      v-model:show="sendModal"
      :show-confirm-button="false"
      :closeOnClickOverlay="true"
      width="90%"
      height="auto"
    >
      <div class="modal-box">
        <div class="modal-ttl">
          <p>
            {{ Number(plateInfo.totalFp).toFixed(3) }} {{ $t("FP staking")
            }}<br />{{ $t("Do you agree?") }}
          </p>
        </div>
        <div class="btn">
          <button
            v-if="!loading.dialogBtn"
            v-on:click="stakingSubmit"
            type="button"
            class="link-over"
          >
            <span>{{ $t("Complete re staking") }}</span>
          </button>
          <button v-else type="button" class="link-over">
            <van-loading />
          </button>
        </div>
      </div>
    </van-dialog>

    <!-- <van-dialog
      v-model:show="certificationModal"
      :show-confirm-button="false"
      :closeOnClickOverlay="true"
      width="90%"
      height="auto"
    >
      <div class="modal-box">
        <form>
          <div class="modal-ttl">
            <p>
              {{ $t("Two-factor authentication") }}
            </p>
            <div class="input-box">
              <input type="text" maxlength="1" />
              <input type="text" maxlength="1" />
              <input type="text" maxlength="1" />
              <input type="text" maxlength="1" />
              <input type="text" maxlength="1" />
              <input type="text" maxlength="1" />
            </div>
          </div>
          <div class="btn">
            <button v-on:click="hide2" type="button" class="link-over">
              {{ $t("Authenticate") }}
            </button>
          </div>
        </form>
      </div>
    </van-dialog> -->

    <Footer title="STAKING" />
  </div>
</template>
<style lang="less" scoped>
.van-dialog {
  background-color: #fff0;
}
</style>
<script>
import Header from "@/components/Header.vue";
import HeaderMenu from "@/components/HeaderMenu.vue";
import Sidebar from "@/components/Sidebar.vue";
import Footer from "@/components/Footer.vue";
import Plate from "../js/api/plate";
import Common from "../js/common";
import { i18n } from "../js/i18n/i18n";
import Definitions from "../js/definitions";
import LoginUser from "../js/loginUser";
export default {
  name: "Staking",
  components: {
    Header,
    HeaderMenu,
    Sidebar,
    Footer,
  },
  data: () => {
    return {
      title: "Staking",
      amountBtc: "",
      sendModal: false,
      loading: {
        dialogBtn: false,
      },
      plateInfo: {
        fp: 0,
        fp2: 0,
        rp: 0,
        sp: 0,
        days: "",
        totalFp: 0,
      },
      certificationModal: false,
    };
  },
  methods: {
    show: function () {
      this.sendModal = true;
    },
    hide: function () {
      this.sendModal = false;
    },
    show2: function () {
      this.certificationModal = true;
    },
    hide2: function () {
      this.certificationModal = false;
    },
    getPlateInfoAsync() {
      if (
        !Common.isEmpty(
          localStorage.getItem(Definitions.StorageKeys.accessToken)
        )
      ) {
        this.userTitle = LoginUser.getUserTitle();
        Plate.getPlateInfo().then((res) => {
          if (res) {
            this.$store.commit("setPlateInfo", res.data);
            setTimeout(() => {
              this.$router.push("/");
            }, 2000);
          }
        });
      }
    },
    certificationAction: function () {
      this.$modal.hide("sendModal");
      this.$modal.show("certificationModal");
    },
    getPlateInfo() {
      this.plateInfo.fp = this.$store.getters.getPlateInfo.fp;
      this.plateInfo.fp2 = this.$store.getters.getPlateInfo.fp2;
      this.plateInfo.rp = this.$store.getters.getPlateInfo.rp;
      this.plateInfo.sp = this.$store.getters.getPlateInfo.sp;
      this.plateInfo.days = this.$store.getters.getPlateInfo.days;
      this.plateInfo.totalFp =
        Number(this.plateInfo.fp) + Number(this.plateInfo.fp2);
    },
    stakingSubmit() {
      this.loading.dialogBtn = true;
      Plate.ReStaking()
        .then(() => {
          this.getPlateInfo();
          Common.success(i18n.global.t("Pledge succeeded."));
          this.getPlateInfoAsync();
        })
        .catch(() => {
          this.loading.dialogBtn = false;
        });
    },
  },
  computed: {
    hasBtcAmount() {
      return this.amountBtc;
    },
  },
  mounted: function () {
    this.getPlateInfo();
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", vh + "px");

    var ua = navigator.userAgent.toLowerCase();
    var isiOS = ua.indexOf("iphone") > -1 || ua.indexOf("ipad") > -1;
    if (isiOS) {
      var viewport = document.querySelector('meta[name="viewport"]');
      if (viewport) {
        var viewportContent = viewport.getAttribute("content");
        viewport.setAttribute(
          "content",
          viewportContent + ", user-scalable=no"
        );
      }
    }
  },
};
</script>
