<template>
  <div class="wrapper authentication-page">
    <!-- Header -->
    <Header />
    <!-- HeaderMenu -->
    <HeaderMenu />

    <div class="contents">
      <div class="layout">
        <div class="left">
          <div class="pc-only">
            <Sidebar />
          </div>
        </div>
        <div class="right">
          <h1>{{ $t("Two-factor authentication") }}</h1>
          <div class="page-contents">
            <div class="page-ttl">
              <h2>{{ $t("Two-factor authentication") }}</h2>
            </div>
            <h3>{{ $t("Download Google Authenticator APP") }}</h3>
            <p class="txt">
              {{
                $t(
                  "iOS users may go to App Store and search for “Google Authenticator” to download the app."
                )
              }}<br />
              {{
                $t(
                  "Android users may access your respective app store and search for “Google Authenticator” to download the app."
                )
              }}
            </p>
            <ul class="list">
              <li>
                <router-link to="#" class="link-over">
                  <div class="img">
                    <img src="../assets/img/google-img1.svg" alt="App Store" />
                  </div>
                  <p class="name">App Store</p>
                </router-link>
              </li>
              <li>
                <router-link to="#" class="link-over">
                  <div class="img">
                    <img
                      src="../assets/img/google-img2.svg"
                      alt="Google Play"
                    />
                  </div>
                  <p class="name">Google Play</p>
                </router-link>
              </li>
              <li>
                <router-link to="#" class="link-over">
                  <div class="img">
                    <img src="../assets/img/google-img3.svg" alt="Android" />
                  </div>
                  <p class="name">Android</p>
                </router-link>
              </li>
            </ul>
            <div v-if="!twoFactor">
              <h3>{{ $t("Add and Backup Auth Key") }}</h3>
              <p class="txt">
                {{
                  $t(
                    "Open the Google Authenticator APP, scan the QR code below, or enter the key manually."
                  )
                }}
                {{ $t("Please save and backup this key.") }}
                {{
                  $t(
                    "It is required for setup in google authenticator when you lost or replaced your smartphone."
                  )
                }}
              </p>
              <div class="box">
                <div class="tab-content">
                  <div class="content">
                    <div class="img">
                      <QRCodeVue3
                        :width="200"
                        :height="200"
                        :value="authenticatorUri"
                        :qrOptions="{
                          typeNumber: 11,
                          mode: 'Byte',
                          errorCorrectionLevel: 'H',
                        }"
                        :dotsOptions="{
                          type: 'classy',
                        }"
                      />
                    </div>
                    <div class="input-area">
                      <input type="text" v-model="authenticatorKey" readonly />
                      <span
                        v-clipboard:copy="authenticatorKey"
                        v-clipboard:success="codeCopy"
                        v-clipboard:error="codeError"
                        >COPY</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <h3>{{ $t("Complete Binding") }}</h3>
              <p class="txt">
                {{
                  $t(
                    "Please ensure you save and backup the above key before entering the 6-Digit Verification Code below."
                  )
                }}
              </p>
              <form>
                <div class="input-area">
                  <input
                    type="text"
                    :placeholder="$t('Google authentication code')"
                    v-model.trim="authenticationCode"
                  />
                </div>
                <div class="btn">
                  <van-button
                    type="button"
                    class="link-over"
                    :loading="loading"
                    @click="bind"
                  >
                    {{ $t("Bind") }}
                  </van-button>
                </div>
              </form>
            </div>
            <div v-else>
              <form>
                <div class="input-area">
                  <input
                    type="text"
                    :placeholder="$t('Google authentication code')"
                    v-model.trim="authenticationCode"
                  />
                </div>
                <div class="btn">
                  <van-button
                    type="button"
                    class="link-over"
                    :loading="loading"
                    @click="disable"
                  >
                    {{ $t("Disable two-factor") }}
                  </van-button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.contents -->

    <Footer :title="title" />
  </div>
</template>
<style scoped></style>
<script>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import Header from "../components/Header.vue";
import HeaderMenu from "../components/HeaderMenu.vue";
import Sidebar from "../components/Sidebar.vue";
import Footer from "../components/Footer.vue";
import LoginUser from "../js/loginUser.js";
import Account from "../js/api/account";
import { i18n } from "../js/i18n/i18n";
import QRCodeVue3 from "qrcode-vue3";
import Common from "../js/common";

export default {
  name: "Authentication",
  components: {
    Header,
    HeaderMenu,
    Sidebar,
    Footer,
    QRCodeVue3,
  },

  setup() {
    let title = i18n.global.t("Two-factor authentication");
    const twoFactor = ref(LoginUser.isTwoFactorEnabled());
    const authenticatorUri = ref("");
    const authenticatorKey = ref("");
    const authenticationCode = ref("");
    const loading = ref(false);

    const getTwoFactorInfo = () => {
      if (twoFactor.value === false) {
        Account.get2FAAsync().then((response) => {
          authenticatorUri.value = response.data.authenticatorUri;
          authenticatorKey.value = response.data.authenticatorKey
            .toUpperCase()
            .replace(/\s*/g, "");
        });
      }
    };

    const router = useRouter();
    const bind = () => {
      loading.value = true;
      Account.enable2FAAsync(authenticationCode.value)
        .then(() => {
          Common.success(
            i18n.global.t("Two-factor authentication is turned on.")
          );
          router.push({ name: "Account" });
        })
        .finally(() => (loading.value = false));
    };

    const disable = () => {
      loading.value = true;
      Account.disable2FAAsync(authenticationCode.value)
        .then(() => {
          Common.success(
            i18n.global.t("Two-factor authentication is turned off.")
          );
          router.push({ name: "Account" });
        })
        .finally(() => (loading.value = false));
    };

    const codeCopy = () => {
      alert("You just copied: " + authenticatorKey.value);
    };

    const codeError = () => {
      alert("Failed to copy texts");
    };

    onMounted(() => {
      getTwoFactorInfo();
    });

    return {
      title,
      twoFactor,
      authenticatorUri,
      authenticatorKey,
      authenticationCode,
      bind,
      disable,
      loading,
      codeCopy,
      codeError,
    };
  },
};
</script>
