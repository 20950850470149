<template>
  <div class="wrapper signin-page">
    <!-- Header -->
    <Header />
    <!-- HeaderMenu -->
    <HeaderMenu />

    <div class="contents">
      <div class="layout">
        <div class="left"></div>
        <div class="right">
          <div class="bg-wrap">
            <div class="img pc-only">
              <img src="../assets/img/logo-img.png" alt="FDP Logo" />
            </div>
            <h1>{{ $t("SIGN IN") }}</h1>
            <div class="form">
              <div v-if="!twoFactor">
                <form>
                  <div class="box">
                    <div class="input-area">
                      <input
                        type="text"
                        v-model.trim="username"
                        :placeholder="$t('YOUR USER NAME')"
                      />
                    </div>
                    <div class="input-area">
                      <input
                        type="password"
                        v-model.trim="password"
                        :placeholder="$t('PASSWORD')"
                      />
                    </div>
                  </div>
                  <div class="form-btn">
                    <van-button
                      native-type="button"
                      class="link-over login-btn"
                      @click="login"
                      :loading="loading"
                      >{{ $t("SIGN IN") }}</van-button
                    >
                  </div>
                  <div class="link-txt">
                    <router-link to="./forgot" class="link-over">{{
                      $t("Forgot user name or password")
                    }}</router-link>
                  </div>
                </form>
              </div>
              <div v-else>
                <form>
                  <div class="box">
                    <div class="input-area">
                      <input
                        type="text"
                        :maxlength="authenticatorCodeLength"
                        v-model.trim="authenticatorCode"
                        :placeholder="$t('Two-factor code')"
                      />
                    </div>
                  </div>
                  <div class="form-btn">
                    <van-button
                      native-type="button"
                      class="link-over login-btn"
                      @click="challengeAuthenticatorCode"
                      :loading="loading"
                      >{{ $t("SIGN IN") }}</van-button
                    >
                  </div>
                  <div class="link-txt">
                    <a class="link-over" @click="backToLogin">{{
                      $t("GO BACK")
                    }}</a>
                  </div>
                </form>
              </div>
            </div>

            <div class="fix-bar">
              <a class="link-over" @click="onSignUp">{{ $t("SIGN UP") }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.contents -->

    <!--<Footer class="pc-only" title="Sign in" />-->
  </div>
</template>

<style lang="less" scoped>
.header {
  :deep(.grade) {
    display: none !important;
  }

  :deep(.detail) {
    display: none !important;
  }
}

// .login-btn {
//   width: 220px;
// }
</style>

<script>
import Header from "@/components/Header.vue";
import HeaderMenu from "@/components/HeaderMenu.vue";
// import Footer from "@/components/Footer.vue";
import { ref, onMounted, watch } from "vue";
import Common from "../js/common";
import Account from "../js/api/account";
import { i18n } from "../js/i18n/i18n";
import { useRouter } from "vue-router";
export default {
  name: "Signin",
  components: {
    Header,
    HeaderMenu,
    // Footer,
  },
  methods: {
    onSignUp() {
      this.$router.push({ name: "Signup" });
    },
  },
  setup() {
    const username = ref("");
    const password = ref("");
    const loading = ref(false);
    const twoFactor = ref(false);
    let twoFactorToken = "";
    const authenticatorCode = ref("");
    const authenticatorCodeLength = 6;

    onMounted(() => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", vh + "px");

      var ua = navigator.userAgent.toLowerCase();
      var isiOS = ua.indexOf("iphone") > -1 || ua.indexOf("ipad") > -1;
      if (isiOS) {
        var viewport = document.querySelector('meta[name="viewport"]');
        if (viewport) {
          var viewportContent = viewport.getAttribute("content");
          viewport.setAttribute(
            "content",
            viewportContent + ", user-scalable=no"
          );
        }
      }
    });

    const router = useRouter();
    const login = () => {
      if (username.value && password.value) {
        const data = {
          username: username.value,
          password: password.value,
          grant_type: "Username_password",
          recaptchaResponse: "dummy",
        };
        loading.value = true;
        Account.loginAsync(data)
          .then((response) => {
            if (response.data.twoFactorToken) {
              twoFactor.value = true;
              twoFactorToken = response.data.twoFactorToken;
            } else {
              loggedIn(response.data);
            }
          })
          .finally(() => {
            loading.value = false;
          });
      }
    };

    const challengeAuthenticatorCode = () => {
      if (authenticatorCode.value.length === authenticatorCodeLength) {
        let twoFactorData = {
          twoFactorToken,
          authenticatorCode: authenticatorCode.value,
        };
        Account.twoFactorAsync(twoFactorData)
          .then((response) => {
            loggedIn(response.data);
          })
          .finally(() => {
            loading.value = false;
          });
      }
    };

    const loggedIn = (data) => {
      Common.loggedIn(data.access_token, data.refresh_token);
      Common.success(i18n.global.t("Login succeed."));
      router.push("/");
    };

    const backToLogin = () => {
      authenticatorCode.value = "";
      twoFactor.value = false;
    };

    watch(
      (authenticatorCode,
      () => {
        if (authenticatorCode.value.length === authenticatorCodeLength) {
          challengeAuthenticatorCode();
        }
      })
    );

    return {
      username,
      password,
      loading,
      twoFactor,
      authenticatorCode,
      authenticatorCodeLength,
      login,
      challengeAuthenticatorCode,
      backToLogin,
    };
  },
};
</script>
