<template>
  <div class="menu-container">
    <div class="menu-sliders"></div>
    <div class="menu-sliders"></div>
    <div class="menu-sliders"></div>
    <div class="menu">
      <ul>
        <li>
          <router-link to="./" class="link-over">マイウォレット</router-link>
        </li>
        <li v-if="isDepositRouterShow()">
          <router-link to="./deposit" class="link-over">チャージ</router-link>
        </li>
        <li v-if="isPlanRouterShow()">
          <router-link to="./plan" class="link-over">プラン購入</router-link>
        </li>
        <li>
          <router-link to="./history" class="link-over">履歴</router-link>
        </li>
        <li>
          <router-link to="./organizationchart" class="link-over"
            >組織</router-link
          >
        </li>
        <li>
          <router-link to="./transfer" class="link-over">転送</router-link>
        </li>
        <li>
          <router-link to="./withdraw" class="link-over">出金</router-link>
        </li>
        <li>
          <router-link to="/account" class="link-over"
            >アカウント情報</router-link
          >
        </li>
        <li>
          <router-link to="./referral" class="link-over"
            >紹介コード</router-link
          >
        </li>
        <li>
          <a class="link-over" @click="loggedOut">ログアウト</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Common from "@/js/common";
export default {
  name: "HeaderMenu",
  props: {},
  mounted: function () {},
  methods: {
    loggedOut() {
      Common.loggedOut();
    },
    isDepositRouterShow() {
      return Common.isDepositRouterShow();
    },
    isPlanRouterShow() {
      return Common.isPlanRouterShow();
    },
  },
};
</script>

<style scoped lang="less"></style>
