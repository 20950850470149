<template>
  <div class="wrapper withdraw-page">
    <!-- Header -->
    <Header />
    <!-- HeaderMenu -->
    <HeaderMenu />

    <div class="contents">
      <div class="layout">
        <div class="left">
          <div class="pc-only">
            <Sidebar />
          </div>
        </div>
        <div class="right">
          <h1>{{ $t("FP WITHDRAWAL") }}</h1>
          <div class="balance">
            <h2>{{ $t("Withdrawal amount") }}</h2>
            <div class="total-amount">
              <span>{{ Number(plateInfo.totalFp).toFixed(3) }}</span> FP
            </div>
            <!-- <p>( 0.000011 BTC )</p> -->
            <ul class="list">
              <li>
                <h3>FP</h3>
                <div class="amount">
                  <span>{{ Number(plateInfo.fp).toFixed(3) }}</span>
                </div>
              </li>
              <li>
                <h3>{{ $t("FP Reward") }}</h3>
                <div class="amount">
                  <span>{{ Number(plateInfo.fp2).toFixed(3) }}</span>
                </div>
              </li>
            </ul>
          </div>
          <div class="form">
            <form>
              <!-- <div class="input-area">
                <label class="label" for="amount">
                  <input
                    type="text"
                    name=""
                    id="amount"
                    placeholder=" "
                    v-model.number="amountBtc"
                  />
                  <span class="group-label"
                    >{{ $t("Withdrawal amount") }}（FP）</span
                  >
                  <span class="upper-amount">(0.00000111 BTC)</span>
                </label>
                <span class="circle" v-bind:class="{ done: hasBtcAmount }">
                  <span class="bar"></span>
                </span>
              </div> -->
              <div class="input-area">
                <label class="label" for="address">
                  <input
                    type="text"
                    name=""
                    id="address"
                    placeholder=" "
                    v-model="addressBtc"
                  />
                  <span class="group-label">{{
                    $t("Enter your FTM address.")
                  }}</span>
                </label>
                <span class="circle" v-bind:class="{ done: hasBtcAddress }">
                  <span class="bar"></span>
                </span>
              </div>
              <div class="notice-txt">
                <!-- <p>最低出金額はOO FPとなります。</p> -->
                <p>{{ $t("The withdrawal fee is 10%.") }}</p>
                <!-- <p>
                  一度でも出金したユーザーは再ステーキングを行ってもバイナリーボーナスを含めた全てのボーナスは受け取れなくなります。
                </p> -->
              </div>
              <div class="all-amount">
                <p>{{ $t("Amount to be withdrawn") }}</p>
                <div class="number">
                  <!-- <span v-if="!amountBtc">- - - - - -</span>
                  <span else>{{ amountBtc }}</span> -->
                  <span>{{
                    (plateInfo.totalFp - plateInfo.totalFp / 10).toFixed(3)
                  }}</span>
                  FP
                </div>
                <div class="tax-amount">
                  （{{
                    $t("Withdrawal fee") +
                    ":" +
                    (plateInfo.totalFp / 10).toFixed(3)
                  }}FP）
                </div>
              </div>
              <div class="btn">
                <button
                  type="button"
                  class="link-over"
                  id="send_btn"
                  v-on:click="show"
                >
                  {{ $t("WITHDRAWAL REQUEST") }}
                </button>
              </div>
            </form>
          </div>
          <div class="table">
            <h2>{{ $t("Withdrawal history") }}</h2>
            <table>
              <tr>
                <th>{{ $t("DATE") }}</th>
                <th>{{ $t("AMOUNT") }}</th>
                <th>{{ $t("STATUS") }}</th>
              </tr>
              <tr v-for="item in tableList" :key="item.id">
                <td>{{ item.applicationDate }}</td>
                <td>{{ item.value }}FP</td>
                <td>{{ item.status }}</td>
              </tr>
              <tr v-if="tableList.length == 0">
                <td></td>
                <td>{{ $t("No data") }}</td>
                <td></td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- /.contents -->

    <van-dialog
      v-model:show="sendModal"
      :show-confirm-button="false"
      :closeOnClickOverlay="true"
      width="90%"
      height="auto"
    >
      <div class="modal-box">
        <div class="modal-ttl">
          <p>
            {{ (plateInfo.totalFp - plateInfo.totalFp / 10).toFixed(3) }}
            {{ $t("FP withdrawal request") }}<br />{{ $t("Do you agree?") }}
          </p>
        </div>
        <div class="btn">
          <button
            v-if="!loading.dialogBtn"
            v-on:click="withdraw"
            type="button"
            class="link-over"
          >
            <span>
              {{ $t("Complete the withdrawal request") }}
            </span>
          </button>
          <button v-else type="button" class="link-over">
            <van-loading />
          </button>
        </div>
      </div>
    </van-dialog>

    <!-- <modal
      name="certificationModal"
      :resizable="true"
      :adaptive="true"
      :scrollable="true"
      width="90%"
      height="auto"
    >
      <div class="modal-box">
        <form>
          <div class="modal-ttl">
            <p>
              {{ $t("Two-factor authentication") }}
            </p>
            <div class="input-box">
              <input type="text" maxlength="1" />
              <input type="text" maxlength="1" />
              <input type="text" maxlength="1" />
              <input type="text" maxlength="1" />
              <input type="text" maxlength="1" />
              <input type="text" maxlength="1" />
            </div>
          </div>
          <div class="btn">
            <button v-on:click="hide2" type="button" class="link-over">
              {{ $t("Authenticate") }}
            </button>
          </div>
        </form>
      </div>
    </modal> -->

    <Footer title="WITHDRAWALFP" />
  </div>
</template>
<style lang="less" scoped>
.van-dialog {
  background-color: #fff0;
}
</style>
<script>
import Header from "@/components/Header.vue";
import HeaderMenu from "@/components/HeaderMenu.vue";
import Sidebar from "@/components/Sidebar.vue";
import Footer from "@/components/Footer.vue";
import Withdrawal from "../js/api/withdrawal";
import Common from "../js/common";
import { i18n } from "../js/i18n/i18n";
import { Notify } from "vant";
import Plate from "../js/api/plate";
import Definitions from "../js/definitions";
import LoginUser from "../js/loginUser";
export default {
  name: "WithdrawFp",
  components: {
    Header,
    HeaderMenu,
    Sidebar,
    Footer,
  },
  data: () => {
    return {
      title: "WithdrawFp",
      amountBtc: "",
      addressBtc: "",
      sendModal: false,
      address: false,
      currentPage: 1,
      tableList: [],
      total: 0,
      loading: { dialogBtn: false },
      plateInfo: {
        fp: 0,
        fp2: 0,
        rp: 0,
        sp: 0,
        days: "",
        totalFp: 0,
      },
    };
  },
  methods: {
    show: function () {
      this.sendModal = true;
    },
    show2: function () {
      this.$modal.show("certificationModal");
    },
    hide2: function () {
      this.$modal.hide("certificationModal");
    },
    certificationAction: function () {
      this.$modal.hide("sendModal");
      this.$modal.show("certificationModal");
    },
    getPlateInfoAsync() {
      if (
        !Common.isEmpty(
          localStorage.getItem(Definitions.StorageKeys.accessToken)
        )
      ) {
        this.userTitle = LoginUser.getUserTitle();
        Plate.getPlateInfo().then((res) => {
          if (res) {
            this.$store.commit("setPlateInfo", res.data);
            setTimeout(() => {
              this.$router.push("/");
            }, 2000);
          }
        });
      }
    },
    getPlateInfo() {
      this.plateInfo.fp = this.$store.getters.getPlateInfo.fp;
      this.plateInfo.fp2 = this.$store.getters.getPlateInfo.fp2;
      this.plateInfo.rp = this.$store.getters.getPlateInfo.rp;
      this.plateInfo.sp = this.$store.getters.getPlateInfo.sp;
      this.plateInfo.days = this.$store.getters.getPlateInfo.days;
      this.plateInfo.totalFp =
        Number(this.plateInfo.fp) + Number(this.plateInfo.fp2);
    },
    withdraw() {
      if (this.addressBtc == "") {
        Notify({
          type: "warning",
          message: i18n.global.t("Address cannot be empty."),
        });
        return;
      }
      const data = {
        withdrawalAddress: this.addressBtc,
        amount: this.plateInfo.totalFp,
        assetName: "STANDARDTOKEN",
        authenticationCode: "",
      };
      this.loading.dialogBtn = true;
      Withdrawal.addWithdrawalAsync(data)
        .then(() => {
          // headerRef.value.getPlateInfo();
          this.getWithdrawal();
          Common.success(
            i18n.global.t("The withdrawal application has been completed.")
          );
          this.getPlateInfoAsync();
        })
        .catch(() => {
          this.loading.dialogBtn = false;
        });
    },
    getWithdrawal() {
      const data = {
        assetName: "STANDARDTOKEN",
        index: this.currentPage - 1,
        take: 10,
      };
      Withdrawal.getWithdrawalAsync(data).then((res) => {
        if (res) {
          this.tableList = res.data.detail;
          this.total = res.data.total;
        }
      });
    },
    onPage(val) {
      this.currentPage = val;
      this.getWithdrawal();
    },
  },
  computed: {
    hasBtcAmount() {
      return this.amountBtc;
    },
    hasBtcAddress() {
      return this.addressBtc;
    },
    hasBtcChecked() {
      return this.amountBtc && this.addressBtc;
    },
  },
  mounted: function () {
    this.getPlateInfo();
    this.getWithdrawal();
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", vh + "px");

    var ua = navigator.userAgent.toLowerCase();
    var isiOS = ua.indexOf("iphone") > -1 || ua.indexOf("ipad") > -1;
    if (isiOS) {
      var viewport = document.querySelector('meta[name="viewport"]');
      if (viewport) {
        var viewportContent = viewport.getAttribute("content");
        viewport.setAttribute(
          "content",
          viewportContent + ", user-scalable=no"
        );
      }
    }
  },
  watch: {
    "$store.state.plateInfo.ap": function () {
      this.getPlateInfo();
    },
  },
};
</script>
