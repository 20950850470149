<template>
  <div class="wrapper dashboard-page">
    <!-- Header -->
    <Header />
    <!-- HeaderMenu -->
    <HeaderMenu />

    <div class="contents">
      <div class="layout">
        <div class="pc-only main">
          <!-- <h1></h1> -->
          <div class="latest-news display1Row" v-if="newsFlag === true">
            <router-link to="/newslist" class="link-over">
              {{ $t("New notification") }}
            </router-link>
          </div>
          <div class="balance">
            <h2>{{ $t("Current staking") }}</h2>
            <div class="total-amount">
              <span>{{ plateInfo.fp }}</span> FP
            </div>
            <div class="total-circle">
              <span>FP {{ $t("REWARD") }}</span> {{ plateInfo.fp2 }}FP
            </div>
            <div class="remail-box">
              {{ $t("Remaining until staking is released")
              }}<span>{{ plateInfo.days }}</span> {{ $t("Day") }}
            </div>
            <ul class="list">
              <li>
                <h3>RP</h3>
                <div class="amount">
                  <span
                    ><b>{{ plateInfo.rp }}</b></span
                  >
                </div>
              </li>
              <li>
                <h3>PP</h3>
                <div class="amount">
                  <span
                    ><b>{{ plateInfo.sp }}</b></span
                  >
                </div>
              </li>
            </ul>
          </div>
          <div class="package">
            <div class="box-wrap">
              <div class="btn">
                <a href="#" v-on:click="show2" class="link-over">{{
                  $t("Explanation of various points")
                }}</a>
              </div>
              <div
                class="btn"
                v-if="!userInfo.isFpWithdrawal && isDepositRouterShow()"
              >
                <router-link to="./deposit" class="link-over">{{
                  $t("CHARGE")
                }}</router-link>
              </div>
              <div class="btn" v-if="isPlanRouterShow()">
                <router-link to="./plan" class="link-over">{{
                  $t("BUYPLAN")
                }}</router-link>
              </div>
              <div
                class="btn staking-btn"
                v-if="!userInfo.isFpWithdrawal && Number(plateInfo.days) == 0"
              >
                <router-link to="./bridge" class="link-over">{{
                  $t("RE-STAKING / WITHDRAWAL")
                }}</router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="left">
          <div class="pc-only">
            <Sidebar />
          </div>
        </div>
        <div class="right">
          <div class="sp-only">
            <!-- <h1></h1> -->
            <div class="latest-news display1Row" v-if="newsFlag === true">
              <router-link to="/newslist" class="link-over">
                {{ $t("New notification") }}
              </router-link>
            </div>
            <div class="balance">
              <h2>{{ $t("Current staking") }}</h2>
              <div class="total-amount">
                <span>{{ plateInfo.fp }}</span> FP
              </div>
              <div class="total-circle">
                <span>FP {{ $t("REWARD") }}</span> {{ plateInfo.fp2 }}FP
              </div>
              <div class="remail-box">
                {{ $t("Remaining until staking is released")
                }}<span>{{ plateInfo.days }}</span> {{ $t("Day") }}
              </div>
              <ul class="list">
                <li>
                  <h3>RP</h3>
                  <div class="amount">
                    <span>{{ plateInfo.rp }}</span>
                  </div>
                </li>
                <li>
                  <h3>PP</h3>
                  <div class="amount">
                    <span>{{ plateInfo.sp }}</span>
                  </div>
                </li>
              </ul>
              <div class="package">
                <div class="link">
                  <span class="img">
                    <img src="../assets/img/info-icon-img.svg" alt="" />
                  </span>
                  <span class="txt link-over" v-on:click="show2">{{
                    $t("Explanation of various points")
                  }}</span>
                </div>
                <div
                  v-if="!userInfo.isFpWithdrawal && isDepositRouterShow()"
                  class="btn"
                >
                  <router-link to="./deposit" class="link-over">{{
                    $t("CHARGE")
                  }}</router-link>
                </div>
                <div class="btn" v-if="isPlanRouterShow()">
                  <router-link to="./plan" class="link-over">{{
                    $t("BUYPLAN")
                  }}</router-link>
                </div>
                <div
                  v-if="!userInfo.isFpWithdrawal && Number(plateInfo.days) == 0"
                  class="btn staking-btn"
                >
                  <router-link to="./bridge" class="link-over">{{
                    $t("RE-STAKING / WITHDRAWAL")
                  }}</router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="table">
            <h2>
              {{ $t("PLAN HISTORY") }}
              <router-link to="./history" class="link-over">{{
                $t("Details")
              }}</router-link>
            </h2>
            <div class="tab-area">
              <div
                class="tab link-over"
                v-on:click="isSelect('Business')"
                v-bind:class="{ active: isActive === 'Business' }"
              >
                BUSINESS
              </div>
              <div
                class="tab link-over"
                v-on:click="isSelect('Investor')"
                v-bind:class="{ active: isActive === 'Investor' }"
              >
                INVESTOR
              </div>
            </div>
            <div
              class="table-wrap"
              v-show="isActive === 'Business'"
              v-bind:class="{ active: isActive === 'Business' }"
            >
              <table>
                <tr>
                  <th>{{ $t("DATE") }}</th>
                  <th>{{ $t("CURRENCY") }}</th>
                  <th>{{ $t("PLAN") }}</th>
                  <th>{{ $t("POINT AMOUNT") }}</th>
                </tr>
                <template v-if="activeList.length > 0">
                  <tr v-for="(item, index) in activeList" :key="index">
                    <td>{{ item.createdTime }}</td>
                    <td>{{ item.currency }}</td>
                    <td>{{ item.bonusSettingText }}</td>
                    <td style="text-align: right">
                      {{ item.pointsText }}&nbsp;
                    </td>
                  </tr>
                </template>
                <tr v-else>
                  <td colspan="4">{{ $t("NO DATA") }}</td>
                </tr>
              </table>
            </div>
            <div
              class="table-wrap"
              v-show="isActive === 'Investor'"
              v-bind:class="{ active: isActive === 'Investor' }"
            >
              <table>
                <tr>
                  <th>{{ $t("DATE") }}</th>
                  <th>{{ $t("CURRENCY") }}</th>
                  <th>{{ $t("PLAN") }}</th>
                  <th>{{ $t("POINT AMOUNT") }}</th>
                </tr>
                <template v-if="activeList.length > 0">
                  <tr v-for="(item, index) in activeList" :key="index">
                    <td>{{ item.createdTime }}</td>
                    <td>{{ item.currency }}</td>
                    <td>{{ item.bonusSettingText }}</td>
                    <td style="text-align: right">
                      {{ item.pointsText }}&nbsp;
                    </td>
                  </tr>
                </template>
                <tr v-else>
                  <td colspan="4">{{ $t("NO DATA") }}</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="table">
            <h2>
              {{ $t("STEAK REWARD HISTORY") }}
              <router-link to="./history" class="link-over">{{
                $t("Details")
              }}</router-link>
            </h2>
            <table>
              <tr>
                <th>{{ $t("DATE") }}</th>
                <th>{{ $t("CURRENCY") }}</th>
                <th>{{ $t("REWARD") }}</th>
              </tr>
              <template v-if="fpList.length > 0">
                <tr v-for="(item, index) in fpList" :key="index">
                  <td>{{ item.createdTime }}</td>
                  <td>
                    {{ item.assetName }}
                  </td>
                  <td style="text-align: right">{{ item.pointsText }}&nbsp;</td>
                </tr>
              </template>
              <tr v-else>
                <td colspan="4">{{ $t("NO DATA") }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- /.contents -->

    <van-popup v-model:show="showRef" style="width: 90%">
      <div class="modal-box notice-box">
        <div class="text-area">
          <h2>{{ $t("Explanation of various points") }}</h2>
          <p>
            <span>USD</span
            >プランの購入や出金が可能なポイントでBTC・ETH・USDTでの入金に対し、エクスチェンジされ付与されます。またボーナス関連もUSDとして付与されます。
          </p>
          <p><span>FP</span>ステーキングしている数</p>
          <p><span>RP</span>プランの増資やユーザーへの転送が可能なポイント</p>
          <p>
            <span>PP</span
            >バイナリーボーナスの上限を超えたポイント（プランの増資のみ可能）
          </p>
        </div>
        <div class="btn">
          <button v-on:click="hide2" type="button" class="link-over">
            {{ $t("Close") }}
          </button>
        </div>
      </div>
    </van-popup>

    <Footer :title="$t('My Wallet')" />
  </div>
</template>
<style scoped lang="less">
.dashboard-page {
  .latest-news {
    text-align: center;
    margin-bottom: 10px;
    background-color: rgba(#0052f6, 0.8);
    padding: 4px 10px 6px;
    a {
      display: inline-block;
      border-bottom: 1px solid #fff;
      color: #fff;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 1px;
      padding-bottom: 2px;
    }
  }
  .display1Row {
    line-height: 1.5;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
<script>
import Header from "@/components/Header.vue";
import HeaderMenu from "@/components/HeaderMenu.vue";
import Sidebar from "@/components/Sidebar.vue";
import Footer from "@/components/Footer.vue";
import Asset from "@/js/api/asset.js";
import Notice from "@/js/api/notice.js";
import Common from "../js/common";

export default {
  name: "Dashboard",
  components: {
    Header,
    HeaderMenu,
    Sidebar,
    Footer,
  },
  data: () => {
    return {
      changeColor: false,
      showRef: false,
      newsFlag: false,
      amount: "",
      userTitle: "",
      isActive: "Business",
      activeList: [],
      fpList: [],
      userInfo: {},
      plateInfo: {
        fp: 0,
        fp2: 0,
        rp: 0,
        sp: 0,
        days: "",
      },
    };
  },
  watch: {
    "$store.state.plateInfo.ap": function () {
      this.getPlateInfo();
    },
  },
  computed: {
    addClass() {
      if (this.amount !== "") {
        return "done";
      } else {
        return "";
      }
    },
  },
  mounted: function () {
    this.init();
    this.getPlateInfo();
    this.isHaveNewNotice();
  },
  created() {
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.getHistories();
    this.getFPs();
  },
  methods: {
    isSelect: function (num) {
      this.isActive = num;
      this.getHistories();
    },
    show2: function () {
      this.showRef = true;
    },
    hide2: function () {
      this.showRef = false;
    },
    getPlateInfo() {
      this.plateInfo.fp = this.$store.getters.getPlateInfo.fp;
      this.plateInfo.fp2 = this.$store.getters.getPlateInfo.fp2;
      this.plateInfo.rp = this.$store.getters.getPlateInfo.rp;
      this.plateInfo.sp = this.$store.getters.getPlateInfo.sp;
      this.plateInfo.days = this.$store.getters.getPlateInfo.days;
    },
    isDepositRouterShow() {
      return Common.isDepositRouterShow();
    },
    isPlanRouterShow() {
      return Common.isPlanRouterShow();
    },
    init() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", vh + "px");

      var ua = navigator.userAgent.toLowerCase();
      var isiOS = ua.indexOf("iphone") > -1 || ua.indexOf("ipad") > -1;
      if (isiOS) {
        var viewport = document.querySelector('meta[name="viewport"]');
        if (viewport) {
          var viewportContent = viewport.getAttribute("content");
          viewport.setAttribute(
            "content",
            viewportContent + ", user-scalable=no"
          );
        }
      }
    },
    getHistories() {
      let data = {
        index: 0,
        take: 5,
        entryPlayType: this.isActive,
        historyType: "PlateActive",
      };
      Asset.getHistories(data).then((res) => {
        if (res) {
          this.activeList = res.data.detail;
        }
      });
    },
    getFPs() {
      let data = {
        index: 0,
        take: 5,
        assetType: "STANDARDTOKEN",
        Range: "Reward",
      };
      Asset.getHistories(data).then((res) => {
        if (res) {
          this.fpList = res.data.detail;
        }
      });
    },
    isHaveNewNotice() {
      Notice.isHaveNewNoticeAsync().then((res) => {
        if (res.data) {
          this.newsFlag = true;
        } else {
          this.newsFlag = false;
        }
      });
    },
  },
};
</script>
