<template>
  <div class="wrapper contact-page">
    <!-- Header -->
    <Header />
    <!-- HeaderMenu -->
    <HeaderMenu />

    <div class="contents">
      <div class="layout">
        <div class="left">
          <div class="pc-only">
            <Sidebar />
          </div>
        </div>
        <div class="right">
          <h1>{{ $t("Contact") }}</h1>
          <p class="notice">
            {{ $t("For inquiries to customer support") }}<br />
            {{ $t("Please send to the email address below.") }}
          </p>
          <div class="form">
            <form>
              <div class="input-area">
                <input type="text" name="userid" :value="userid" readonly />
              </div>
              <div class="input-area select">
                <select name="type" v-model="type">
                  <option value="" selected>
                    {{ $t("Select your inquiry") }}
                  </option>
                  <option :value="$t('Login / Password')">
                    {{ $t("Login / Password") }}
                  </option>
                  <option :value="$t('Deposit / Withdrawal')">
                    {{ $t("Deposit / Withdrawal") }}
                  </option>
                  <option :value="$t('Two-step verification / Email address')">
                    {{ $t("Two-step verification / Email address") }}
                  </option>
                  <option :value="$t('Plan Purchase')">
                    {{ $t("Plan Purchase") }}
                  </option>
                  <option :value="$t('Bonus / FP Rewards')">
                    {{ $t("Bonus / FP Rewards") }}
                  </option>
                  <option :value="$t('Cancellation')">
                    {{ $t("Cancellation") }}
                  </option>
                  <option :value="$t('Others')">
                    {{ $t("Others") }}
                  </option>
                </select>
              </div>
              <div class="input-area">
                <input
                  type="email"
                  name="email"
                  v-model="mail"
                  :placeholder="$t('Email address')"
                />
              </div>
              <div class="input-area">
                <textarea
                  name="question"
                  :placeholder="$t('Please enter your question in detail.')"
                  v-model="content"
                ></textarea>
              </div>
              <div class="form-btn" @click="submit">
                <button type="button" class="link-over">
                  {{ $t("Contact") }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /.contents -->

    <Footer title="Contact" />
    <van-overlay :show="loading">
      <div class="loading-position" @click.stop>
        <van-loading type="spinner" color="#1989fa" />
      </div>
    </van-overlay>
  </div>
</template>
<style scoped lang="less">
.wrapper.contact-page {
  background-color: #0836c0;
  background-image: url(/img/dashboard-other-back-img.png);
  background-position: 50% 180px;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  @media screen and (min-width: 767px) {
    background: url(/img/dashboard-pc-back-img.png), #0836c0;
    background-position: top;
    background-repeat: no-repeat;
    background-size: 100%;
  }
}
.loading-position {
  position: absolute;
  left: calc(50% - 15px);
  top: calc(50% - 15px);
}

/* Contact-page
----------------------------------------- */
.contact-page {
  .right {
    padding: 80px 0 40px;
    @media screen and (min-width: 767px) {
      padding: 0 0 20px;
    }
  }
  h1 {
    color: #fff;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 1px;
    margin: 40px 0 20px;
    padding: 6px 20px 6px 28px;
    position: relative;
    text-align: center;
  }
  .notice {
    padding: 0 20px;
    text-align: center;
    margin-bottom: 60px;
    color: #fff;
    line-height: 1.9;
    letter-spacing: 2px;
    font-size: 12px;
  }
  .form {
    padding: 0 40px;
    .input-area {
      margin: 0 auto;
      background-color: #fff;
      padding: 10px 30px;
      border-radius: 6px;
      box-shadow: 0 5px 10px -2px rgba(0, 0, 0, 0.2),
        0 2px 4px -1px rgba(0, 0, 0, 0.3);
      position: relative;
      margin: 20px 0;
      &.select {
        &:before {
          content: "▼";
          color: #3e6cf2;
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translateY(-50%) translateX(0%);
        }
      }
      input {
        color: #000;
        width: 100%;
        border: none;
        appearance: none;
        padding: 8px 10px;
        border-bottom: 2px solid #3e6cf2;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1px;
        outline: none;
      }
      select {
        color: #000;
        width: 100%;
        border: none;
        appearance: none;
        padding: 8px 10px;
        border-bottom: 2px solid #3e6cf2;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1px;
        outline: none;
        background-color: #fff;
      }
      textarea {
        color: #000;
        width: 100%;
        border: none;
        appearance: none;
        padding: 8px 10px;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1px;
        outline: none;
        height: 120px;
      }
    }
    .form-btn {
      text-align: center;
      margin-top: 60px;
      button {
        font-size: 14px;
        padding: 12px 60px;
        border-radius: 30px;
        letter-spacing: 2px;
        box-shadow: 0 5px 10px -2px rgba(0, 0, 0, 0.2),
          0 2px 4px -1px rgba(0, 0, 0, 0.3);
        background-color: #fff;
        color: #3e6cf2;
      }
    }
  }
}
</style>
<script>
import Header from "@/components/Header.vue";
import HeaderMenu from "@/components/HeaderMenu.vue";
import Sidebar from "@/components/Sidebar.vue";
import Footer from "@/components/Footer.vue";
import LoginUser from "@/js/loginUser.js";
import { Notify } from "vant";
import Notice from "@/js/api/notice.js";

export default {
  name: "Contact",
  components: {
    Header,
    HeaderMenu,
    Sidebar,
    Footer,
  },
  data: () => {
    return {
      title: "Contact",
      mail: "",
      type: "",
      userid: LoginUser.getUserName(),
      content: "",
      loading: false,
    };
  },
  methods: {
    submit() {
      //验证输入项目
      if (!this.type) {
        Notify({
          type: "danger",
          message: this.$t("Select your inquiry"),
        });
        return;
      }
      if (!this.mail) {
        Notify({
          type: "danger",
          message: this.$t("{item} is required.", {
            item: this.$t("Email address"),
          }),
        });
        return;
      }
      if (!this.content) {
        Notify({
          type: "danger",
          message: this.$t("Please enter your question in detail."),
        });
        return;
      }

      //提交数据
      let postData = {
        title: `${this.type}(${this.mail})`,
        content: this.content,
      };
      this.loading = true;
      Notice.postInquiryAsync(postData)
        .then(() => {
          this.content = "";
          this.type = "";
          this.mail = "";
          Notify({ type: "success", message: "success" });
        })
        .finally(() => (this.loading = false));
    },
  },
  computed: {},
  mounted: function () {},
};
</script>
