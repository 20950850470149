<template>
  <div class="wrapper withdraw-page">
    <!-- Header -->
    <Header ref="headerRef" />
    <!-- HeaderMenu -->
    <HeaderMenu />

    <div class="contents">
      <div class="layout">
        <div class="left">
          <div class="pc-only">
            <Sidebar />
          </div>
        </div>
        <div class="right">
          <h1>{{ $t("WITHDRAW") }}</h1>
          <div class="balance">
            <h2>{{ $t("Amount can be withdrawn") }}</h2>
            <div class="total-amount">
              <span>{{ balance }}</span> USD
            </div>
            <p>( {{ balanceBtc.toFixed(8) }} BTC )</p>
          </div>
          <div class="form">
            <form>
              <span style="color: red; font-size: 12px"
                >*{{ $t("Minimum withdrawal amount 200USD") }}</span
              >
              <div class="input-area">
                <label class="label" for="amount">
                  <input
                    type="text"
                    name=""
                    id="amount"
                    placeholder=" "
                    v-model.number="amountUsd"
                  />
                  <span class="group-label">{{
                    $t("Enter the amount to withdraw.")
                  }}</span>
                  <span class="upper-amount">({{ btcPirce }} USD/BTC)</span>
                </label>
                <span class="circle" v-bind:class="{ done: hasAmount }">
                  <span class="bar"></span>
                </span>
              </div>
              <div class="input-area">
                <label class="label" for="address">
                  <input type="text" v-model="address" />
                  <span class="group-label">{{
                    $t("Enter your BTC address.")
                  }}</span>
                </label>
                <span class="circle" v-bind:class="{ done: hasAddress }">
                  <span class="bar"></span>
                </span>
              </div>
              <div class="all-amount">
                <p>{{ $t("Amount to be withdrawn") }}</p>
                <div class="number">
                  <span v-if="!amountUsd">- - - - - -</span>
                  <span v-else>{{ amountBtc.toFixed(8) }}</span>
                  BTC
                </div>
              </div>
              <div class="btn">
                <van-button
                  type="button"
                  class="link-over"
                  v-on:click="showSendModal"
                  v-bind:disabled="!hasChecked"
                  :loading="withdrawing"
                >
                  {{ $t("WITHDRAW") }}
                </van-button>
              </div>
            </form>
          </div>
          <div class="table">
            <h2>{{ $t("WITHDRAW HISTORY") }}</h2>
            <table>
              <tr>
                <th>{{ $t("DATE") }}</th>
                <th>{{ $t("AMOUNT") }}</th>
                <th>{{ $t("STATUS") }}</th>
              </tr>
              <template v-if="tableList.length > 0">
                <tr v-for="(item, index) in tableList" :key="index">
                  <td>{{ item.applicationDate }}</td>
                  <td>{{ item.value }} USD</td>
                  <td>{{ item.statusText }}</td>
                </tr>
              </template>
              <tr v-else>
                <td colspan="4">{{ $t("NO DATA") }}</td>
              </tr>
            </table>
            <van-pagination
              v-if="total > rowsPerPage"
              v-model="currentPage"
              :total-items="total"
              :show-page-size="3"
              :items-per-page="rowsPerPage"
              @change="onPage"
              force-ellipses
            >
              <template #prev-text>
                {{ $t("PREVIOUS") }}
              </template>
              <template #next-text>
                {{ $t("NEXT") }}
              </template>
            </van-pagination>
          </div>
        </div>
      </div>
    </div>
    <!-- /.contents -->
    <van-popup v-model:show="sendModal" style="width: 90%">
      <div class="modal-box">
        <div class="modal-ttl">
          <p>
            {{ amountBtc.toFixed(8) }} BTC <br class="sp-only" />{{
              $t("Are you sure you want to withdraw ?")
            }}
          </p>
        </div>
        <div class="btn">
          <button v-on:click="hideSendModal" type="button" class="link-over">
            {{ $t("Complete withdrawal") }}
          </button>
        </div>
      </div>
    </van-popup>
    <van-popup v-model:show="certificationModal" style="width: 90%">
      <div class="modal-box">
        <form>
          <div class="modal-ttl">
            <p>
              {{ $t("Two-factor authentication") }}
            </p>
            <div class="input-box">
              <input
                type="text"
                maxlength="1"
                ref="twoFactor1"
                v-model.trim="authenticationCode1"
              />
              <input
                type="text"
                maxlength="1"
                ref="twoFactor2"
                v-model="authenticationCode2"
                @keydown="handleKeydown2"
              />
              <input
                type="text"
                maxlength="1"
                ref="twoFactor3"
                v-model="authenticationCode3"
                @keydown="handleKeydown3"
              />
              <input
                type="text"
                maxlength="1"
                ref="twoFactor4"
                v-model="authenticationCode4"
                @keydown="handleKeydown4"
              />
              <input
                type="text"
                maxlength="1"
                ref="twoFactor5"
                v-model="authenticationCode5"
                @keydown="handleKeydown5"
              />
              <input
                type="text"
                maxlength="1"
                ref="twoFactor6"
                v-model="authenticationCode6"
                @keydown="handleKeydown6"
              />
            </div>
          </div>
          <div class="btn">
            <button
              v-on:click="hideCertificationModal"
              type="button"
              class="link-over"
            >
              {{ $t("Authenticate") }}
            </button>
          </div>
        </form>
      </div>
    </van-popup>
    <Footer :title="title" />
  </div>
</template>
<style scoped></style>
<script>
import Header from "../components/Header.vue";
import HeaderMenu from "../components/HeaderMenu.vue";
import Sidebar from "../components/Sidebar.vue";
import Footer from "../components/Footer.vue";
import { i18n } from "../js/i18n/i18n";
import { useStore } from "vuex";
import {
  ref,
  onMounted,
  computed,
  defineComponent,
  nextTick,
  watch,
} from "vue";
import Withdrawal from "../js/api/withdrawal";
import Common from "../js/common";
import LoginUser from "../js/loginUser";

export default defineComponent({
  name: "Withdraw",
  components: {
    Header,
    HeaderMenu,
    Sidebar,
    Footer,
  },
  setup() {
    const title = i18n.global.t("WITHDRAW");
    const store = useStore();
    const balance = computed(() => parseFloat(store.state.plateInfo.ap));
    const headerRef = ref();
    const btcPirce = ref(0.0);
    let fixedFee = 0;
    let feeRate = 0.0;
    const balanceBtc = computed(() =>
      btcPirce.value == 0
        ? 0
        : ((balance.value - fixedFee) * (1 - feeRate)) / btcPirce.value
    );
    const amountUsd = ref();
    const amountBtc = computed(
      () => ((amountUsd.value - fixedFee) * (1 - feeRate)) / btcPirce.value
    );
    const hasAmount = computed(() => amountUsd.value);
    const address = ref("");
    const hasAddress = computed(() => address.value);
    const hasChecked = computed(() => amountUsd.value && address.value);
    const sendModal = ref(false);
    const certificationModal = ref(false);

    Withdrawal.getPricesAsync().then((res) => {
      btcPirce.value = res.data.btc;
      fixedFee = res.data.fixedFee;
      feeRate = res.data.feeRate;
    });

    const showSendModal = () => {
      if (amountUsd.value < 300) {
        Common.error(
          i18n.global.t("USD amount must be greater than or equal to 200.")
        );
      } else if (amountUsd.value > balance.value) {
        Common.error(i18n.global.t("INSUFFICIENT BALANCE"));
      } else {
        sendModal.value = true;
      }
    };

    const hideSendModal = () => {
      sendModal.value = false;
      if (LoginUser.isTwoFactorEnabled()) {
        showCertificationModal();
      } else {
        withdraw();
      }
    };

    const twoFactor1 = ref();
    const twoFactor2 = ref();
    const twoFactor3 = ref();
    const twoFactor4 = ref();
    const twoFactor5 = ref();
    const twoFactor6 = ref();
    const authenticationCode1 = ref("");
    const authenticationCode2 = ref("");
    const authenticationCode3 = ref("");
    const authenticationCode4 = ref("");
    const authenticationCode5 = ref("");
    const authenticationCode6 = ref("");
    const showCertificationModal = () => {
      authenticationCode1.value = "";
      authenticationCode2.value = "";
      authenticationCode3.value = "";
      authenticationCode4.value = "";
      authenticationCode5.value = "";
      authenticationCode6.value = "";
      certificationModal.value = true;
      nextTick(() => {
        twoFactor1.value.focus();
      });
    };
    const authenticationCode = computed(
      () =>
        authenticationCode1.value +
        authenticationCode2.value +
        authenticationCode3.value +
        authenticationCode4.value +
        authenticationCode5.value +
        authenticationCode6.value
    );
    watch(authenticationCode1, (newValue) => {
      if (newValue.length === 1) {
        twoFactor2.value.focus();
      }
    });
    watch(authenticationCode2, (newValue) => {
      if (newValue.length === 1) {
        twoFactor3.value.focus();
      }
    });
    watch(authenticationCode3, (newValue) => {
      if (newValue.length === 1) {
        twoFactor4.value.focus();
      }
    });
    watch(authenticationCode4, (newValue) => {
      if (newValue.length === 1) {
        twoFactor5.value.focus();
      }
    });
    watch(authenticationCode5, (newValue) => {
      if (newValue.length === 1) {
        twoFactor6.value.focus();
      }
    });
    const handleKeydown2 = (e) => {
      if (e.key === "Backspace" && authenticationCode2.value === "") {
        twoFactor1.value.focus();
      }
    };
    const handleKeydown3 = (e) => {
      if (e.key === "Backspace" && authenticationCode3.value === "") {
        twoFactor2.value.focus();
      }
    };
    const handleKeydown4 = (e) => {
      if (e.key === "Backspace" && authenticationCode4.value === "") {
        twoFactor3.value.focus();
      }
    };
    const handleKeydown5 = (e) => {
      if (e.key === "Backspace" && authenticationCode5.value === "") {
        twoFactor4.value.focus();
      }
    };
    const handleKeydown6 = (e) => {
      if (e.key === "Backspace" && authenticationCode6.value === "") {
        twoFactor5.value.focus();
      }
    };

    const hideCertificationModal = () => {
      if (authenticationCode.value.length === 6) {
        certificationModal.value = false;
        withdraw();
      }
    };

    const withdrawing = ref(false);
    const withdraw = () => {
      withdrawing.value = true;
      const data = {
        withdrawalAddress: address.value,
        amount: amountUsd.value,
        assetName: "AP",
        authenticationCode: authenticationCode.value,
      };
      Withdrawal.addWithdrawalAsync(data)
        .then(() => {
          headerRef.value.getPlateInfo();
          getWithdrawal();
          Common.success(
            i18n.global.t("The withdrawal application has been completed.")
          );
          address.value = "";
          amountUsd.value = "";
        })
        .finally(() => (withdrawing.value = false));
    };

    const tableList = ref([]);
    const currentPage = ref(1);
    const rowsPerPage = 5;
    const total = ref(0);
    const getWithdrawal = () => {
      const data = {
        assetName: "AP",
        index: currentPage.value - 1,
        take: rowsPerPage,
      };
      Withdrawal.getWithdrawalAsync(data).then((res) => {
        if (res) {
          tableList.value = res.data.detail;
          total.value = res.data.total;
        }
      });
    };
    const onPage = (val) => {
      currentPage.value = val;
      getWithdrawal();
    };

    onMounted(() => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", vh + "px");

      var ua = navigator.userAgent.toLowerCase();
      var isiOS = ua.indexOf("iphone") > -1 || ua.indexOf("ipad") > -1;
      if (isiOS) {
        var viewport = document.querySelector('meta[name="viewport"]');
        if (viewport) {
          var viewportContent = viewport.getAttribute("content");
          viewport.setAttribute(
            "content",
            viewportContent + ", user-scalable=no"
          );
        }
      }

      getWithdrawal();
    });

    return {
      title,
      balance,
      balanceBtc,
      headerRef,
      btcPirce,
      amountUsd,
      amountBtc,
      address,
      hasAmount,
      hasAddress,
      hasChecked,
      showSendModal,
      hideSendModal,
      hideCertificationModal,
      sendModal,
      certificationModal,
      withdraw,
      withdrawing,
      tableList,
      currentPage,
      rowsPerPage,
      total,
      getWithdrawal,
      onPage,
      twoFactor1,
      twoFactor2,
      twoFactor3,
      twoFactor4,
      twoFactor5,
      twoFactor6,
      authenticationCode1,
      authenticationCode2,
      authenticationCode3,
      authenticationCode4,
      authenticationCode5,
      authenticationCode6,
      handleKeydown2,
      handleKeydown3,
      handleKeydown4,
      handleKeydown5,
      handleKeydown6,
    };
  },
});
</script>
