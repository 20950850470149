<template>
  <footer class="footer">
    <div class="inner">
      <div class="page-nav">
        <ul class="list">
          <li>
            <router-link to="./" class="link-over">TOP</router-link>
          </li>
          <li>
            <router-link to="#" class="link-over">{{ $t(title) }}</router-link>
          </li>
        </ul>
      </div>
      <div class="language">
        <div class="logo sp-only">
          <img src="../assets/img/logo-img.png" alt="Logo" />
        </div>
        <div class="select-box" v-on:click="active">
          <p class="japanese" v-show="currentLanguage === 'ja-jp'">
            {{ $t("JAPANESE") }}
          </p>
          <p class="english" v-show="currentLanguage === 'en-us'">
            {{ $t("ENGLISH") }}
          </p>
          <p class="chinese" v-show="currentLanguage === 'zh-cn'">
            {{ $t("CHINESE") }}
          </p>
          <div class="select" v-show="isActive">
            <div
              class="option japanese"
              v-on:click.prevent="changeLanguage('ja-jp')"
            >
              {{ $t("JAPANESE") }}
            </div>
            <div
              class="option english"
              v-on:click.prevent="changeLanguage('en-us')"
            >
              {{ $t("ENGLISH") }}
            </div>
            <div
              class="option chinese"
              v-on:click.prevent="changeLanguage('zh-cn')"
            >
              {{ $t("CHINESE") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <ul class="footer-fixed-menu">
      <li>
        <router-link to="./" class="link-over">
          <div class="img">
            <img src="../assets/img/footer-fixed-menu1-img.png" alt="" />
          </div>
          <p>{{ $t("My Wallet") }}</p>
        </router-link>
      </li>
      <li>
        <router-link to="./organizationchart" class="link-over">
          <div class="img">
            <img src="../assets/img/footer-fixed-menu2-img.png" alt="" />
          </div>
          <p>{{ $t("Organization") }}</p>
        </router-link>
      </li>
      <li v-if="isPlanRouterShow()">
        <router-link to="./plan" class="link-over">
          <div class="img">
            <img src="../assets/img/footer-fixed-menu3-img.png" alt="" />
          </div>
          <p>{{ $t("PLAN") }}</p>
        </router-link>
      </li>
      <li>
        <router-link to="./account" class="link-over">
          <div class="img">
            <img src="../assets/img/footer-fixed-menu4-img.png" alt="" />
          </div>
          <p>{{ $t("ACCOUNT") }}</p>
        </router-link>
      </li>
    </ul>
  </footer>
</template>

<script>
import { ref } from "vue";
import { setLanguage } from "../js/i18n/i18n";
import Definitions from "../js/definitions";
import Common from "../js/common";
export default {
  name: "Footer",
  props: ["title"],
  setup() {
    const isActive = ref(false);
    const currentLanguage = ref(
      localStorage.getItem(Definitions.StorageKeys.language)
    );

    const changeLanguage = (lang) => {
      setLanguage(lang);
      currentLanguage.value = lang;
    };

    const active = () => {
      isActive.value = !isActive.value;
    };
    const isPlanRouterShow = () => {
      return Common.isPlanRouterShow();
    };

    return {
      isActive,
      currentLanguage,
      changeLanguage,
      active,
      isPlanRouterShow,
    };
  },
};
</script>

<style scoped lang="less"></style>
