import Fetch from "@/js/api/fetch";

const Plate = {};

Plate.getPlateInfo = () => Fetch.get("/Plate/GetPlateInfo");

Plate.activePlate = (data) => Fetch.post("/Plate/ActivePlate", data);

Plate.ReStaking = () => Fetch.post("/plate/ReStaking");

export default Plate;
