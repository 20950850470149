<template>
  <div class="wrapper deposit-page">
    <!-- Header -->
    <Header />
    <!-- HeaderMenu -->
    <HeaderMenu />

    <div class="contents">
      <div class="layout">
        <div class="left">
          <div class="pc-only">
            <Sidebar />
          </div>
        </div>
        <div class="right">
          <h1>{{ $t("CHARGE") }}</h1>
          <div class="tab-area">
            <div
              class="tab link-over"
              v-on:click="isSelect('BTC')"
              v-bind:class="{ active: currentAssetName === 'BTC' }"
            >
              BTC
            </div>
            <div
              class="tab link-over"
              v-on:click="isSelect('ETH')"
              v-bind:class="{ active: currentAssetName === 'ETH' }"
            >
              ETH<br />(ERC20)
            </div>
            <div
              class="tab link-over"
              v-on:click="isSelect('USDT(TRC20)')"
              v-bind:class="{ active: currentAssetName === 'USDT(TRC20)' }"
            >
              USDT<br />(TRC20)
            </div>
            <div
              class="tab link-over"
              v-on:click="isSelect('USDT(BEP20)')"
              v-bind:class="{ active: currentAssetName === 'USDT(BEP20)' }"
            >
              USDT<br />(BEP20)
            </div>
          </div>
          <div
            class="content-area"
            v-show="currentAssetName === 'BTC'"
            v-bind:class="{ active: currentAssetName === 'BTC' }"
          >
            <div class="balance" v-if="!addressBTC">
              <div class="address-btn">
                <span class="link-over" v-on:click="createAddress('BTC')">{{
                  $t("CREATE WALLET ADDRESS")
                }}</span>
              </div>
            </div>
            <div class="balance" v-else>
              <div class="input-area">
                <input
                  type="text"
                  :placeholder="$t('USD amount you want to charge')"
                  v-model="requiredBtcUsd"
                />
                <van-button
                  type="button"
                  class="link-over btn-calculation"
                  @click="calculate('BTC')"
                >
                  {{ $t("Calculation") }}
                </van-button>
                <div class="amount-hint">
                  {{ $t("※Minimum deposit 100 USD") }}
                </div>
              </div>
              <div class="exchange">
                <div class="currency">
                  {{ $t("Amount required for charge") }}
                </div>
                <div class="price">
                  <span>{{ requiredAmountBTC }}</span
                  >BTC
                </div>
              </div>
              <div class="wallet-img">
                <QRCodeVue3
                  :width="200"
                  :height="200"
                  :value="addressBTC"
                  :qrOptions="{
                    typeNumber: 11,
                    mode: 'Byte',
                    errorCorrectionLevel: 'H',
                  }"
                  :dotsOptions="{
                    type: 'classy',
                  }"
                />
              </div>
              <div class="copy-address">
                <div class="input-area">
                  <input type="text" v-model="addressBTC" readonly />
                  <span
                    class="copy-btn"
                    v-clipboard:copy="addressBTC"
                    v-clipboard:success="addressCopy"
                    v-clipboard:error="addressError"
                    >{{ $t("COPY") }}</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div
            class="content-area"
            v-show="currentAssetName === 'ETH'"
            v-bind:class="{ active: currentAssetName === 'ETH' }"
          >
            <div class="balance" v-if="!addressETH">
              <div class="address-btn">
                <span class="link-over" v-on:click="createAddress('ETH')">{{
                  $t("CREATE WALLET ADDRESS")
                }}</span>
              </div>
            </div>
            <div class="balance" v-else>
              <div class="input-area">
                <input
                  type="text"
                  :placeholder="$t('USD amount you want to charge')"
                  v-model="requiredEthUsd"
                />
                <van-button
                  type="button"
                  class="link-over btn-calculation"
                  @click="calculate('ETH')"
                >
                  {{ $t("Calculation") }}
                </van-button>
                <div class="amount-hint">
                  {{ $t("※Minimum deposit 100 USD") }}
                </div>
              </div>
              <div class="exchange">
                <div class="currency">
                  {{ $t("Amount required for charge") }}
                </div>
                <div class="price">
                  <span>{{ requiredAmountETH }}</span
                  >ETH
                </div>
              </div>
              <div class="wallet-img">
                <QRCodeVue3
                  :width="200"
                  :height="200"
                  :value="addressETH"
                  :qrOptions="{
                    typeNumber: 11,
                    mode: 'Byte',
                    errorCorrectionLevel: 'H',
                  }"
                  :imageOptions="{
                    hideBackgroundDots: true,
                    imageSize: 0.4,
                    margin: 0,
                  }"
                  :dotsOptions="{
                    type: 'classy',
                  }"
                />
              </div>
              <div class="copy-address">
                <div class="input-area">
                  <input type="text" v-model="addressETH" readonly />
                  <span
                    class="copy-btn"
                    v-clipboard:copy="addressETH"
                    v-clipboard:success="addressCopy"
                    v-clipboard:error="addressError"
                    >{{ $t("COPY") }}</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div
            class="content-area"
            v-show="currentAssetName === 'USDT(TRC20)'"
            v-bind:class="{ active: currentAssetName === 'USDT(TRC20)' }"
          >
            <div class="balance" v-if="!addressTRX">
              <div class="address-btn">
                <span
                  class="link-over"
                  v-on:click="createAddress('USDT(TRC20)')"
                  >{{ $t("CREATE WALLET ADDRESS") }}</span
                >
              </div>
            </div>
            <div class="balance" v-else>
              <div class="input-area">
                <input
                  type="text"
                  :placeholder="$t('USD amount you want to charge')"
                  v-model="requiredUsdtTrc20Usd"
                />
                <van-button
                  type="button"
                  class="link-over btn-calculation"
                  @click="calculate('USDT(TRC20)')"
                >
                  {{ $t("Calculation") }}
                </van-button>
                <div class="amount-hint">
                  {{ $t("※Minimum deposit 100 USD") }}
                </div>
              </div>
              <div class="exchange">
                <div class="currency">
                  {{ $t("Amount required for charge") }}
                </div>
                <div class="price">
                  <span>{{ requiredAmountUsdtTrc20 }}</span
                  >USDT
                </div>
              </div>
              <div class="wallet-img">
                <QRCodeVue3
                  :width="200"
                  :height="200"
                  :value="addressTRX"
                  :qrOptions="{
                    typeNumber: 11,
                    mode: 'Byte',
                    errorCorrectionLevel: 'H',
                  }"
                  :imageOptions="{
                    hideBackgroundDots: true,
                    imageSize: 0.4,
                    margin: 0,
                  }"
                  :dotsOptions="{
                    type: 'classy',
                  }"
                />
              </div>
              <div class="copy-address">
                <div class="input-area">
                  <input type="text" v-model="addressTRX" readonly />
                  <span
                    class="copy-btn"
                    v-clipboard:copy="addressTRX"
                    v-clipboard:success="addressCopy"
                    v-clipboard:error="addressError"
                    >{{ $t("COPY") }}</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div
            class="content-area"
            v-show="currentAssetName === 'USDT(BEP20)'"
            v-bind:class="{ active: currentAssetName === 'USDT(BEP20)' }"
          >
            <div class="balance" v-if="!addressBNB">
              <div class="address-btn">
                <span
                  class="link-over"
                  v-on:click="createAddress('USDT(BEP20)')"
                  >{{ $t("CREATE WALLET ADDRESS") }}</span
                >
              </div>
            </div>
            <div class="balance" v-else>
              <div class="input-area">
                <input
                  type="text"
                  :placeholder="$t('USD amount you want to charge')"
                  v-model="requiredUsdtBep20Usd"
                />
                <van-button
                  type="button"
                  class="link-over btn-calculation"
                  @click="calculate('USDT(BEP20)')"
                >
                  {{ $t("Calculation") }}
                </van-button>
                <div class="amount-hint">
                  {{ $t("※Minimum deposit 100 USD") }}
                </div>
              </div>
              <div class="exchange">
                <div class="currency">
                  {{ $t("Amount required for charge") }}
                </div>
                <div class="price">
                  <span>{{ requiredAmountUsdtBep20 }}</span
                  >USDT
                </div>
              </div>
              <div class="wallet-img">
                <QRCodeVue3
                  :width="200"
                  :height="200"
                  :value="addressBNB"
                  :qrOptions="{
                    typeNumber: 11,
                    mode: 'Byte',
                    errorCorrectionLevel: 'H',
                  }"
                  :imageOptions="{
                    hideBackgroundDots: true,
                    imageSize: 0.4,
                    margin: 0,
                  }"
                  :dotsOptions="{
                    type: 'classy',
                  }"
                />
              </div>
              <div class="copy-address">
                <div class="input-area">
                  <input type="text" v-model="addressBNB" readonly />
                  <span
                    class="copy-btn"
                    v-clipboard:copy="addressBNB"
                    v-clipboard:success="addressCopy"
                    v-clipboard:error="addressError"
                    >{{ $t("COPY") }}</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="table">
            <h2>{{ $t("CHARGE HISTORY") }}</h2>
            <table>
              <tr>
                <th>{{ $t("DATE") }}</th>
                <th>{{ $t("POINT") }}</th>
                <th>{{ $t("CHARGE AMOUNT") }}</th>
              </tr>
              <template v-if="tableList.length > 0">
                <tr v-for="(item, index) in tableList" :key="index">
                  <td>{{ formatDate(item.createdTimeStamp) }}</td>
                  <td>{{ item.assetName }}</td>
                  <td>{{ item.depositValue.toFixed(2) }}</td>
                </tr>
              </template>
              <tr v-else>
                <td colspan="4">{{ $t("NO DATA") }}</td>
              </tr>
            </table>
            <van-pagination
              v-if="total > 4"
              v-model="currentPage"
              :total-items="total"
              :show-page-size="3"
              :items-per-page="rowsPerPage"
              @change="onPage"
              force-ellipses
            >
              <template #prev-text>
                {{ $t("PREVIOUS") }}
              </template>
              <template #next-text>
                {{ $t("NEXT") }}
              </template>
            </van-pagination>
          </div>
        </div>
      </div>
    </div>
    <!-- /.contents -->

    <Footer :title="title" />
  </div>
</template>
<style scoped>
.btn-calculation {
  height: 23px;
}
.amount-hint {
  font-size: 12px;
  color: red;
  margin-top: 10px;
  text-align: left;
}
</style>
<script>
import Header from "../components/Header.vue";
import HeaderMenu from "../components/HeaderMenu.vue";
import Sidebar from "../components/Sidebar.vue";
import Footer from "../components/Footer.vue";
import { ref, onMounted } from "vue";
import { i18n } from "../js/i18n/i18n";
import QRCodeVue3 from "qrcode-vue3";
import LoginUser from "../js/loginUser";
import Deposit from "../js/api/deposit";
import moment from "moment";
export default {
  name: "Deposit",
  components: {
    Header,
    HeaderMenu,
    Sidebar,
    Footer,
    QRCodeVue3,
  },
  setup() {
    const title = ref(i18n.global.t("DEPOSIT"));
    const currentAssetName = ref("BTC");
    const addressBTC = ref(LoginUser.getUserInfo().depositAddresses.BTC);
    const addressETH = ref(LoginUser.getUserInfo().depositAddresses.ETH);
    const addressTRX = ref(LoginUser.getUserInfo().depositAddresses.TRX);
    const addressBNB = ref(LoginUser.getUserInfo().depositAddresses.BNB);
    const requiredAmountBTC = ref(parseFloat(0).toFixed(4));
    const requiredAmountETH = ref(parseFloat(0).toFixed(4));
    const requiredAmountUsdtTrc20 = ref(parseFloat(0).toFixed(4));
    const requiredAmountUsdtBep20 = ref(parseFloat(0).toFixed(4));
    const requiredBtcUsd = ref("");
    const requiredEthUsd = ref("");
    const requiredUsdtTrc20Usd = ref("");
    const requiredUsdtBep20Usd = ref("");
    const tableList = ref([]);
    const currentPage = ref(1);
    const total = ref(0);
    const rowsPerPage = 10;

    const isSelect = (active) => {
      if (currentAssetName.value == active) return;
      currentAssetName.value = active;
      tableList.value = [];
      currentPage.value = 1;
      total.value = 0;
      getHistory();
    };

    const addressCopy = (e) => {
      alert("You just copied: " + e.text);
    };

    const addressError = () => {
      alert("Failed to copy texts");
    };

    let isCreatingAddress = false;
    const createAddress = (assetName) => {
      if (isCreatingAddress) return;
      isCreatingAddress = true;
      Deposit.createAddress(assetName)
        .then((response) => {
          switch (assetName) {
            case "BTC":
              addressBTC.value = response.data;
              break;
            case "ETH":
              addressETH.value = response.data;
              break;
            case "USDT(TRC20)":
              addressTRX.value = response.data;
              break;
            case "USDT(BEP20)":
              addressBNB.value = response.data;
              break;
          }
        })
        .finally(() => (isCreatingAddress = false));
    };

    let btcPrice = 0;
    let ethPrice = 0;
    const getPrices = () => {
      Deposit.getPrices().then((res) => {
        if (res) {
          btcPrice = res.data.btc;
          ethPrice = res.data.eth;
        }
      });
    };

    let usdtFeeRate = 0;
    const getFeeRate = () => {
      Deposit.getFeeRate().then((res) => {
        if (res) {
          usdtFeeRate = res.data.usdt;
          console.log(usdtFeeRate);
        }
      });
    };

    const calculate = (assetName) => {
      switch (assetName) {
        case "BTC":
          if (isNaN(requiredBtcUsd.value)) {
            requiredAmountBTC.value = parseFloat(0).toFixed(4);
          } else {
            requiredAmountBTC.value = parseFloat(
              requiredBtcUsd.value / btcPrice
            ).toFixed(4);
          }
          break;
        case "ETH":
          if (isNaN(requiredEthUsd.value)) {
            requiredAmountETH.value = parseFloat(0).toFixed(4);
          } else {
            requiredAmountETH.value = parseFloat(
              requiredEthUsd.value / ethPrice
            ).toFixed(4);
          }
          break;
        case "USDT(TRC20)":
          if (isNaN(requiredUsdtTrc20Usd.value)) {
            requiredAmountUsdtTrc20.value = parseFloat(0).toFixed(4);
          } else {
            requiredAmountUsdtTrc20.value = parseFloat(
              requiredUsdtTrc20Usd.value / (1 - usdtFeeRate)
            ).toFixed(4);
          }
          break;
        case "USDT(BEP20)":
          if (isNaN(requiredUsdtBep20Usd.value)) {
            requiredAmountUsdtBep20.value = parseFloat(0).toFixed(4);
          } else {
            requiredAmountUsdtBep20.value = parseFloat(
              requiredUsdtBep20Usd.value / (1 - usdtFeeRate)
            ).toFixed(4);
          }
          break;
      }
    };

    const formatDate = (value) => {
      return moment(value).format("YYYY-MM-DD HH:mm:ss");
    };

    const getHistory = () => {
      let data = {
        assetName: currentAssetName.value,
        index: currentPage.value - 1,
        take: rowsPerPage,
      };
      Deposit.getHistory(data).then((res) => {
        if (res) {
          tableList.value = res.data.detail;
          total.value = res.data.total;
        }
      });
    };

    const onPage = (val) => {
      currentPage.value = val;
      getHistory();
    };

    onMounted(() => {
      getPrices();
      getFeeRate();
      getHistory();
    });

    return {
      title,
      currentAssetName,
      addressBTC,
      addressETH,
      addressTRX,
      addressBNB,
      requiredAmountBTC,
      requiredAmountETH,
      requiredAmountUsdtTrc20,
      requiredAmountUsdtBep20,
      requiredBtcUsd,
      requiredEthUsd,
      requiredUsdtTrc20Usd,
      requiredUsdtBep20Usd,
      isSelect,
      addressCopy,
      addressError,
      createAddress,
      calculate,
      formatDate,
      tableList,
      total,
      currentPage,
      onPage,
      rowsPerPage,
    };
  },
};
</script>
