<template>
  <div class="wrapper terms-page">
    <!-- Header -->
    <Header />
    <!-- HeaderMenu -->
    <HeaderMenu />

    <div class="contents">
      <div class="layout">
        <div class="left">
          <div class="pc-only">
            <Sidebar />
          </div>
        </div>
        <div class="right">
          <h1>{{ $t("Terms of service") }}</h1>
          <div class="pdf">
            <img src="../assets/pdf/terms-pdf-1.jpg" alt="" />
            <img src="../assets/pdf/terms-pdf-2.jpg" alt="" />
            <img src="../assets/pdf/terms-pdf-3.jpg" alt="" />
            <img src="../assets/pdf/terms-pdf-4.jpg" alt="" />
            <img src="../assets/pdf/terms-pdf-5.jpg" alt="" />
            <img src="../assets/pdf/terms-pdf-6.jpg" alt="" />
            <img src="../assets/pdf/terms-pdf-7.jpg" alt="" />
            <img src="../assets/pdf/terms-pdf-8.jpg" alt="" />
            <img src="../assets/pdf/terms-pdf-9.jpg" alt="" />
            <img src="../assets/pdf/terms-pdf-10.jpg" alt="" />
            <img src="../assets/pdf/terms-pdf-11.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
    <!-- /.contents -->

    <Footer title="Terms of service" />
  </div>
</template>
<style scoped lang="less">
.wrapper.terms-page {
  background-color: #0836c0;
  background-image: url(/img/dashboard-other-back-img.png);
  background-position: 50% 180px;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  @media screen and (min-width: 767px) {
    background: url(/img/dashboard-pc-back-img.png), #0836c0;
    background-position: top;
    background-repeat: no-repeat;
    background-size: 100%;
  }
}

/* Terms-page
----------------------------------------- */
.terms-page {
  .right {
    padding: 80px 0 40px;
    @media screen and (min-width: 767px) {
      padding: 0 0 20px;
    }
  }
  h1 {
    color: #fff;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 1px;
    margin: 40px 0 20px;
    padding: 6px 20px 6px 28px;
    position: relative;
    text-align: center;
  }
  .pdf {
    height: 200px;
    overflow: scroll;
    width: 100%;
    text-align: center;
    padding: 0 20px;
    @media screen and (min-width: 767px) {
      height: 200px;
    }
    img {
      width: 100%;
    }
  }
}
</style>
<script>
import Header from "@/components/Header.vue";
import HeaderMenu from "@/components/HeaderMenu.vue";
import Sidebar from "@/components/Sidebar.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "Terms",
  components: {
    Header,
    HeaderMenu,
    Sidebar,
    Footer,
  },
  data: () => {
    return {
      title: "Terms",
    };
  },
  methods: {},
  computed: {},
  mounted: function () {},
};
</script>
