import Common from "../common";
import LoginUser from "../loginUser";
import Token from "./token";
import { i18n } from "../i18n/i18n";
import Router from "../../router";
import Axios from "axios";
import Fetch from "./fetch";

const Account = {
  loginAsync: (data) => Token.postAsync(data, true),

  twoFactorAsync: (data) => Token.twoFactorAsync(data),

  registerAsync: (data) =>
    Axios.post(`${Common.generateApiUrl("account/register")}`, data)
      .then(function () {
        Common.success(i18n.global.t("Register succeed."));
        Router.push({ name: "Login" });
      })
      .catch((error) => {
        Common.handleError(error);
      }),

  getLoginInfoAsync: () =>
    Fetch.get("/account/userInfo").then((response) => {
      if (response && response.data) {
        if (response.data.stopSystem != 0) {
          Router.push({ name: "Maintenance" });
        }
        LoginUser.setUserInfo(response.data);
      }
    }),

  getExistedUser: (username, password) =>
    Fetch.get("/Account/IsExisted", {
      params: {
        username,
        password,
      },
    }),

  register: (data) => Fetch.post("Account/RegisterDownLine", data),

  seatBottom: (bottomType) =>
    Fetch.get("/account/SeatBottom", {
      params: {
        bottomType,
      },
    }),

  getListSeatUser: (username) =>
    Fetch.get("/account/ListSeatUser", {
      params: {
        username,
      },
    }),

  upLevel: (username) =>
    Fetch.get("/account/UpLevel", {
      params: {
        username,
      },
    }),

  listDownLines: (index, take) =>
    Fetch.get("/account/ListDownLines", {
      params: {
        index,
        take,
      },
    }),

  changePassword: (data) => Fetch.post("/account/changePassword", data),

  isEmailExists: (email) =>
    Fetch.get("/account/isEmailExists", {
      params: {
        email,
      },
    }),
  getCommunityInfo: () => Fetch.get("/account/GetCommunityInfo"),
  sendEmaileVerificationCode: (data) =>
    Fetch.post("/account/sendEmailVerificationCode", data),

  sendEmailVerificationCodeSelf: (data) =>
    Fetch.post("/account/sendEmailVerificationCodeSelf", data),

  verifyEmail: (data) => Fetch.post("/account/verifyEmail", data),

  verifyEmailSelf: (data) => Fetch.post("/account/verifyEmailSelf", data),

  sendResetPasswordCodeByEmail: (email) =>
    Axios.post(
      `${Common.generateApiUrl(
        "/account/sendResetPasswordCodeByEmail"
      )}&email=${email}`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).catch((error) => {
      Common.handleError(error);
    }),

  validateEmailAndUserName: (data) =>
    Fetch.post("Account/ValidateEmailAndUserName", data),

  resetPassword: (data) => Fetch.post("Account/resetPassword", data),

  get2FAAsync: () => Fetch.get("/account/twoFactorInfo"),

  enable2FAAsync: (code) =>
    Fetch.post(`/account/Enable2FA`, JSON.stringify(code), {
      headers: {
        "Content-Type": "application/json",
      },
    }),

  disable2FAAsync: (code) =>
    Fetch.post("/account/disable2FA", JSON.stringify(code), {
      headers: {
        "Content-Type": "application/json",
      },
    }),
};

export default Account;
